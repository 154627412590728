import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { DataStoreService, DataStoreType } from 'kinvey-angular-sdk';
import {NewsArticle} from '../models/news-article';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  newsArticles: NewsArticle[];
  selectedNewsArticle: NewsArticle;

  constructor(private cd: ChangeDetectorRef, private zone: NgZone, private dataStoreService: DataStoreService) { }

  ngOnInit() {
      const store = this.dataStoreService.collection('NewsArticles', DataStoreType.Network);
      store.find()
          .subscribe((newsArticles) => {
            // this.zone.run(() => {
                this.newsArticles = newsArticles;
            // })
            // this.cd.detectChanges();
          });
  }

  onSelect(newsArticle: NewsArticle): void {
      this.selectedNewsArticle = newsArticle;
  }

  onBackButton() {
      this.selectedNewsArticle = null;
  }
}
