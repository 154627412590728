import { Component, OnInit, ViewChild, OnDestroy, NgZone  } from '@angular/core';
import { SDUserService } from '../user.service';
// import { UserService } from 'kinvey-angular-sdk';
import {User} from '../models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {ApiService} from '../api-service';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy  {

  years: number[];
  pyears: number[];
  months: string[];
  selectedBirthYear: number;
  selectedPurchaseMonth: string;
  selectedPurchaseYear: number;
  email: string;
  organizationName: string;
  isLoading: boolean;
  @ViewChild('surveyCompleteModal', { static: true }) private surveyCompleteModal;
  @ViewChild('organizationModal', { static: true }) private organizationModal;
  @ViewChild('emailChangeModal', { static: true }) private emailChangeModal;
    sub: any;

  constructor(private sdUserService: SDUserService, private router: Router, private route: ActivatedRoute,
              private modalService: NgbModal, private zone: NgZone/*, private userService: UserService*/,
              private cookieService: CookieService, private apiService: ApiService, private oAuthService: OAuthService) { }

  ngOnInit() {
    this.isLoading = false;
    this.years = [];
    const year = (new Date()).getFullYear();
    for (let i = 99; i >= 0; i--) {
      this.years.push(year - i);
    }
    this.pyears = [];
    for (let py = year; py > 2014; py-- ) {
        this.pyears.push(py);
    }
    this.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];


    this.sdUserService.getUser().subscribe((userObject: User) => {
      this.selectedBirthYear = userObject.preference.birthYear;
      const purchaseDate = new Date(userObject.preference.purchaseDate);
      this.selectedPurchaseMonth = this.months[purchaseDate.getMonth()];
      this.selectedPurchaseYear = purchaseDate.getFullYear();
      /*const kinveyUser = this.userService.getActiveUser();
      this.email = kinveyUser.email;
      const data: any = kinveyUser.data;
      this.organizationName = data.organizationName;*/
      this.email = userObject.email;
      this.organizationName = userObject.preference.organizationName;
    });

      this.sub = this.route
          .queryParams
          .subscribe(params => {
              const didCompleteSurvey: boolean = params['survey'];
              if (didCompleteSurvey) {
                  // hmm hacky solution to ExpressionChangedAfterItHasBeenCheckedError
                  setTimeout(() => {
                      this.modalService.open(this.surveyCompleteModal).result.then(result => {
                          // TODO: this is not how the other apps behave
                          this.router.navigate(['/home']);
                      });
                  });
              }
          });
      }

  ngOnDestroy() {
      this.saveUser();
      this.sub.unsubscribe();
  }

  async onEmailChange() {
      // console.log('email change');
      // TODO this is generating an error, but still functions
      // see https://github.com/ng-bootstrap/ng-bootstrap/issues/1252
      this.isLoading = true;
      await this.apiService.putUser({
          email: this.email,
          username: this.email
      }).toPromise();
      this.isLoading = false;
      sessionStorage.setItem('id_token', 'nothing');
      this.oAuthService.logOut(false);
      this.cookieService.delete('standata.user');
      this.sdUserService.refreshUser();
      const result = await this.modalService.open(this.emailChangeModal).result;
      this.oAuthService.initCodeFlow();

      /*this.userService.logout()
          .then(() => {
              this.cookieService.delete('standata.user');
              this.sdUserService.refreshUser();
          });
      this.modalService.open(this.emailChangeModal).result.then((result) => {
          this.router.navigate(['/login']);
      });*/
      /*this.userService.update({
          email: this.email,
          username: this.email
      }).then((user) => {
          this.zone.run(() => {
              this.isLoading = false;
              this.userService.logout()
                  .then(() => {
                      this.cookieService.delete('standata.user');
                      this.sdUserService.refreshUser();
                  });
              this.modalService.open(this.emailChangeModal).result.then((result) => {
                  this.router.navigate(['/login']);
              });

          });
      }).catch((error) => {
          this.isLoading = false;
          console.log(error.message);
      });*/
  }

  onSubmit() {
    // this.saveUser();
    this.router.navigate(['/survey']);
  }

  showOrgMessage() {
      this.modalService.open(this.organizationModal);
  }

  saveUser() {
      this.sdUserService.getUser().subscribe((userObject: User) => {
          userObject.email = this.email;
          userObject.preference.birthYear = this.selectedBirthYear;
          const purchaseMonthString = '0' + (this.months.indexOf(this.selectedPurchaseMonth) + 1).toString();
          console.log(purchaseMonthString);
          const purchaseDateString = this.selectedPurchaseYear.toString() + '-' +
              purchaseMonthString.slice(purchaseMonthString.length - 2, purchaseMonthString.length) + '-01T05:00:00.000Z';
          console.log(purchaseDateString);
          userObject.preference.purchaseDate = purchaseDateString;
          this.sdUserService.saveUser(userObject);
      });
  }

}
