import { Component, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import {SupportArticle} from '../models/support-article';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-support-detail',
  templateUrl: './support-detail.component.html',
  styleUrls: ['./support-detail.component.scss']
})
export class SupportDetailComponent implements AfterViewInit {
  @Input() supportArticle: SupportArticle;
  public safeUrl: any;
  constructor(public sanitizer: DomSanitizer, private changeDetector: ChangeDetectorRef) { }

    ngAfterViewInit() {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' +
            this.supportArticle.VideoId  + '?rel=0');
        this.changeDetector.detectChanges();
    }


}
