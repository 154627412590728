import {Injectable, OnDestroy} from '@angular/core';
import {TestServiceOneService} from './test-service-one.service';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestServiceTwoService implements OnDestroy {

  public value;
  public timeStamp;
  private subscription: Subscription

  constructor(private testServiceOne: TestServiceOneService) {
      this.subscription = new Subscription();
      // const testServiceOneSuscription = testServiceOne.getValue.subscribe(value => {
      //   this.value = value;
      // });
      // this.subscription.add(testServiceOneSuscription);
      testServiceOne.getValue.subscribe(value => {
          this.value = value;
      });
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

}
