import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Observer, Subject, Subscription} from 'rxjs';
import { Event } from './models/event';
import { User } from './models/user';
// import { DataStoreService, DataStoreType } from 'kinvey-angular-sdk';
import { SDUserService} from './user.service';
import {last, share} from 'rxjs/operators';
import {SurveyResponse} from './models/survey-response';
import {environment} from '../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {ActivityService} from './activity.service';
import * as moment from 'moment';
import {ApiService} from './api-service';

export enum LifeDeskStatus {
    NOT_MOVING,
    MOVING_UP,
    MOVING_DOWN,
    MOVING_TO_MEMORY_ONE,
    MOVING_TO_MEMORY_TWO,
    MOVING_TO_TARGET
}

@Injectable({
    providedIn: 'root'
})
export class LifeDeskService implements OnDestroy {

    public isConnected: boolean;
    private heightSubject: BehaviorSubject<number>;
    private connectedSubject: BehaviorSubject<boolean>;
    private postureSubject: BehaviorSubject<string>;
    public heightInInches: number;

    // private commandObserver: Observer<number>;
    private commandSubject: Subject<number>;
    private commandCount: number;
    private lastHeightChange: Date;
    private targetHeight: number;
    // private targetHeightSubject: BehaviorSubject<number>;
    private commandTimer;
    private deskTypeSubject: Subject<string>;

    private LIFEDESK_DEVICE_INFO_SERVICE_UUID: number;
    private LIFEDESK_DEVICE_SERVICE_UUID: number;
    private LIFEDESK_DATA_IN_CHARACTERISTIC_UUID: number;
    private LIFEDESK_DATA_OUT_CHARACTERISTIC_UUID: number;
    private lifeDeskDevice;
    private lifeDeskService;
    private lifeDeskDataInCharacteristic;
    private lifeDeskDataOutCharacteristic;
    private dataFragment;
    public status: LifeDeskStatus;
    private idleTimer;
    public isPairing: boolean;
    public position: string;
    public lifeDeskPeripheralName: string;

    public alertObservable: Observable<{title: string, body: string }>;
    private alertObserver: Observer<{title: string, body: string }>;
    private alertTimers: any[];
    private user: User = null;
    private subscription: Subscription;

    public usbDevice;
    private usbBuffer = new Uint8Array(100); // should be long enough to process any packets received
    private usbBufferIndex = 0;

    public setDeskType(deskType: string) {
        const now = new Date();
        const future = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
        this.cookieService.set('deskType', deskType, future );
        this.deskTypeSubject.next(deskType);
    }

    constructor(private sdUserService: SDUserService, private cookieService: CookieService,
                private activityService: ActivityService, private ngZone: NgZone,
                /* private dataStoreService: DataStoreService*/private apiService: ApiService) {
        console.log('life desk service constructor');
        this.LIFEDESK_DEVICE_INFO_SERVICE_UUID = 0x180a;
        this.LIFEDESK_DEVICE_SERVICE_UUID = 0xff12;
        this.LIFEDESK_DATA_IN_CHARACTERISTIC_UUID = 0xff01;
        this.LIFEDESK_DATA_OUT_CHARACTERISTIC_UUID = 0xff02;
        this.isConnected = false;
        this.isPairing = false;
        this.dataFragment = null;

        this.status = LifeDeskStatus.NOT_MOVING;

        this.alertObservable = new Observable( observer => {
            this.alertObserver = observer;
        });
        this.alertTimers = [];
        this.commandCount = 0;

        this.heightSubject = new BehaviorSubject(22);
        this.connectedSubject = new BehaviorSubject(false);
        this.postureSubject = new BehaviorSubject((this.user) ? this.user.lastEvent.value : 'away');
        this.deskTypeSubject = new Subject();

        // ========= subscriptions =========
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            console.log('lifedeskservice got the user');
            this.user = user;
            if (this.user && this.user.lastEvent) {
                this.postureSubject.next(this.user.lastEvent.value);
            }
        });
        this.subscription.add(userSubscription);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public heightObservable(): Observable<number> {
        return this.heightSubject.asObservable();
    }

    public connectedObservable(): Observable<boolean> {
        return this.connectedSubject.asObservable();
    }

    /* // tells the home screen when the target height is changed
    // i.e. by another process such as responding to an alert
    public targetHeightObservable(): Observable<number> {
        return this.targetHeightSubject.asObservable();
    }*/

    public moveToTargetHeightObservable(heightInInches: number): Observable<number> {
        // this.targetHeightSubject.next(heightInInches); // pass through intended target height to UI
        if (!this.commandSubject) {
            this.commandCount = 0;
            this.lastHeightChange = new Date();
            this.commandSubject = new Subject();
            this.targetHeight = heightInInches;
            this.heightSubject.next(heightInInches); // pass intended target height to UI
            if (this.commandTimer) {
                clearInterval(this.commandTimer);
            }
            this.commandTimer = setInterval(function() {this.createCommandTimerTask(); }.bind(this), 200);
        }
        return this.commandSubject.asObservable();
    }

    public postureObservable(): Observable<string> {
        return this.postureSubject.asObservable();
    }

    public deskTypeObservable(): Observable<string> {
        return this.deskTypeSubject.asObservable();
    }

    createCommandTimerTask() {
        if (this.commandSubject) {
            if (Math.round(this.targetHeight * 10) === Math.round(this.heightInInches * 10)) {
                clearInterval(this.commandTimer);
                this.commandTimer = null;
                this.commandSubject.next(this.heightInInches);
                this.commandSubject.complete();
                this.commandSubject = null;
                const eventName = (this.heightInInches >= environment.STAND_THRESHOLD) ? 'stand' : 'sit';
                this.position = eventName;
                this.logEvent(eventName, this.lifeDeskPeripheralName);
            } else {
                const  secondsSinceLastHeightChange: number = Math.round(((new Date()).getTime() - this.lastHeightChange.getTime()) / 1000);
                if (secondsSinceLastHeightChange > 2) {
                    clearInterval(this.commandTimer);
                    this.commandTimer = null;
                    this.commandSubject.error('Desk stopped responding.');
                    this.commandSubject = null;
                    this.heightSubject.next(this.heightInInches); // pass last recorded target height to UI
                    const eventName = (this.heightInInches >= environment.STAND_THRESHOLD) ? 'stand' : 'sit';
                    this.position = eventName;
                    this.logEvent(eventName, this.lifeDeskPeripheralName);
                } else {
                    if (this.commandCount === 5) {
                        this.getStatus(); // check status every once in a while. It won't do this if you're sending steady commands
                        this.commandCount = 0;
                    } else {
                        console.log(this.targetHeight);
                        this.moveToTargetHeight(this.targetHeight);
                        this.commandCount ++;
                    }
                }
            }
        }
    }

    connectLifeDesk(deviceName: string) {
        let options;
        if (deviceName === '') {
            options = {filters: [{services: [ this.LIFEDESK_DEVICE_SERVICE_UUID ]}, {namePrefix: 'BLE Device-'}]};
        } else {
            console.log('filtering by exact name');
            options = {filters: [{name: deviceName}], optionalService: [this.LIFEDESK_DEVICE_SERVICE_UUID]};
        }
        let nav: any;
        nav = navigator;

        nav.bluetooth.requestDevice(options)
            .then(device => {
                console.log('GATT name:' + device.name);
                console.log('GATT id: ' + device.id);
                console.log('GATT body: ' + device.body);
                this.lifeDeskDevice = device;
                this.lifeDeskDevice.addEventListener('gattserverdisconnected', this.onDisconnected.bind(this));
                return device.gatt.connect();
            })
            .then(server => server.getPrimaryService(this.LIFEDESK_DEVICE_SERVICE_UUID))
            .then(service => {
                this.lifeDeskService = service;
                return Promise.all([
                    service.getCharacteristic(this.LIFEDESK_DATA_IN_CHARACTERISTIC_UUID)
                        .then(characteristic => {
                            this.lifeDeskDataInCharacteristic = characteristic;
                            console.log('got data in characteristic');
                        }),
                    service.getCharacteristic(this.LIFEDESK_DATA_OUT_CHARACTERISTIC_UUID)
                        .then(characteristic => {
                            this.lifeDeskDataOutCharacteristic = characteristic;
                            console.log('got data out characteristic');
                            this.isConnected = true;
                            this.lifeDeskPeripheralName = this.lifeDeskDevice.name;
                            console.log('connected to ' + this.lifeDeskPeripheralName);
                            /*if (this.connectedObserver) {
                                this.connectedObserver.next(this.isConnected);
                            }*/
                            return this.lifeDeskDataOutCharacteristic.startNotifications().then(_ => {
                                console.log('Data out notifications started');
                                this.lifeDeskDataOutCharacteristic.addEventListener('characteristicvaluechanged',
                                    this.onDataOut.bind(this));
                                this.getStatusWithRetries();
                                this.connectedSubject.next(this.isConnected);
                            });
                        })
                ]);
            })
            .catch(error => {
                console.error('Connection failed!', error);
            });
    }

    onDisconnected(event) {
        // Object event.target is Bluetooth Device getting disconnected.
        console.log('LifeDesk disconnected');
        this.isConnected = false;
        this.logEvent('away', this.lifeDeskPeripheralName);
        this.cancelAllNotifications();
        this.lifeDeskPeripheralName = '';
        this.connectedSubject.next(this.isConnected);
    }

    disconnectLifeDesk() {
        if (!this.lifeDeskDevice) {
            return;
        }
        console.log('Disconnecting LifeDesk...');
        if (this.lifeDeskDevice.gatt.connected) {
            this.lifeDeskDevice.gatt.disconnect();
        } else {
            console.log('LifeDesk is already disconnected');
        }
    }

    /* USB */

    connectLifeDeskUsb() {
        let options;
        options = {filters: [{ vendorId: 0x067b, productId: 0x2303 }]};
        let nav: any;
        nav = navigator;

        if (nav.serial) {
            console.log('experimental features enabled');
            /*nav.serial.requestPort(options)
                .then(port => {
                    port.open({baudrate: 9600});
                });*/
        }

        nav.usb.requestDevice(options)
            .then(device => {
                this.usbDevice = device;
                const readLoop = () => {
                    const {endpointNumber} = this.usbDevice.configuration.interfaces[0].alternate.endpoints[2];
                    this.usbDevice.transferIn(endpointNumber, 64).then(result => {
                        this.onUsbReceive(result.data);
                        readLoop();
                    }, error => {
                        this.onUsbReceiveError(error);
                    });
                };
                this.usbDevice.open()
                    .then(() => {
                        console.log('opened');
                        if (this.usbDevice.configuration === null) {
                            return this.usbDevice.selectConfiguration(1);
                        }
                    })
                    .then(() => this.usbDevice.claimInterface(0))
                    .then(() => {
                        console.log(this.usbDevice);
                        this.isConnected = true;
                        readLoop();
                        this.getStatusWithRetries();
                        this.connectedSubject.next(this.isConnected);
                    });
            });
        /*
        let options;
        options = {filters: [{ vendorId: 0x067b}]};
        let nav: any;
        nav = navigator;
        nav.serial.requestPort(options)
            .then(port => {
                port.open({ baudrate: 9600 })
                    .then(() => {
                        const reader = port.in.getReader();
                        let reading = true;
                        while (reading) {
                            reader.read()
                                .then(readResult => {
                                    if (readResult.done) { reading = false; }
                                    console.log(readResult.data);
                                });
                        }
                    });
            });
         */
    }

    onUsbReceive(data) {
        for (let i = 0; i < data.byteLength; i++) {
            const thisByte = data.getUint8(i);
            this.usbBuffer[this.usbBufferIndex] = thisByte;
            this.usbBufferIndex ++;
            if (thisByte === 126) {
                const packet = new DataView(this.usbBuffer.buffer, 0, this.usbBufferIndex);
                this.usbBufferIndex = 0;
                this.processPacket(packet);
            }
        }
    }

    onUsbReceiveError(error) {
        // report error
    }

    sendUsbData(data) {
        const {endpointNumber} = this.usbDevice.configuration.interfaces[0].alternate.endpoints[1];
        this.usbDevice.transferOut(endpointNumber, data);
    }

    onDataOut(event) {
        const value = event.target.value;
        let allData;
        if (this.dataFragment) {
            allData = new Uint8Array([this.dataFragment, value.byteLength]);
            this.dataFragment = null;
        } else {
            allData = value;
        }

        if (allData.length === 0) {return; }

        const lastByte: number = allData.getUint8(allData.byteLength - 1);
        // console.log(lastByte);
        if (lastByte !== 126) {
            // fragment
            this.dataFragment = allData;
        } else {
            // check for multiple packets
            const packets = [];
            let rangeBegin = 0;
            let rangeLength = 0;
            for (let i = 0; i < allData.byteLength; i++) {
                const thisByte = allData.getUint8(i);
                rangeLength ++;
                if (thisByte === 126) { // 0x7E indicates end of packet
                    const subData = new DataView(allData.buffer, rangeBegin, rangeBegin + rangeLength);
                    packets.push(subData);
                    rangeBegin = i + 1;
                    rangeLength = 0;
                }
            }
            for (const data of packets) {
                this.processPacket(data);
            }
        }

    }

    processPacket(packet) {
        const deskFunction = packet.getUint8(2);
        switch (deskFunction) {
            case 1: // 0x01 height display
            {
                const heightInTenthInches = packet.getUint16(4);
                const newHeightInInches = heightInTenthInches / 10.0;
                console.log(newHeightInInches.toString());
                if (Math.round(newHeightInInches * 10) !== Math.round(this.heightInInches * 10) || this.isPairing) {
                    this.heightInInches = newHeightInInches;
                    this.lastHeightChange = new Date();
                    if (!this.commandSubject) { // don't update height if in the middle of command
                        this.heightSubject.next(this.heightInInches);
                        this.resetIdleTimer();
                    }
                }
            }
        }
    }

    logData(data) {
        const a = [];
        for (let i = 0; i < data.byteLength; i++) {
            a.push('0x' + ('00' + data.getUint8(i).toString(16)).slice(-2));
        }
        console.log(a.join(' '));
    }

    moveUp() {
        const data = new Uint8Array([0xF1, 0xF1, 0x01, 0x00, 0x01, 0x7E]);
        this.lifeDeskDataInCharacteristic.writeValue(data);
    }

    moveDown() {
        const data = new Uint8Array([0xF1, 0xF1, 0x02, 0x00, 0x02, 0x7E]);
        this.lifeDeskDataInCharacteristic.writeValue(data);
    }

    getStatus() {
        const data = new Uint8Array([0xF1, 0xF1, 0x07, 0x00, 0x07, 0x7E]);
        if (this.usbDevice && this.usbDevice.opened) {
            this.sendUsbData(data);
        } else {
            this.lifeDeskDataInCharacteristic.writeValue(data);
        }
    }

    getStatusWithRetries() {
        const data = new Uint8Array([0xF1, 0xF1, 0x07, 0x00, 0x07, 0x7E]);
        this.sendDataToLifeDesk(data, 3);
    }

    stopAction() {
        const data = new Uint8Array([0xF1, 0xF1, 0x0a, 0x00, 0x0a, 0x7E]);
        this.lifeDeskDataInCharacteristic.writeValue(data);
    }

    moveToTargetHeight(heightInInches: number) {
        const lifeDeskFunction = 27;
        const length = 2;
        const heightInMillimeteres = Math.round(heightInInches * 25.4) + 2;
        const datah = Math.floor(heightInMillimeteres / 256);
        const datal = heightInMillimeteres % 256;
        const checksum = lifeDeskFunction + length + datah + datal;
        const data = new Uint8Array([0xF1, 0xF1, lifeDeskFunction, length, datah, datal, checksum, 0x7E]);
        if (this.usbDevice && this.usbDevice.opened) {
            this.sendUsbData(data);
        } else {
            this.lifeDeskDataInCharacteristic.writeValue(data);
        }
    }

    getVersion() {
        const data = new Uint8Array([0xF1, 0xF1, 0x1C, 0x00, 0x1C, 0x7E]);
        this.lifeDeskDataInCharacteristic.writeValue(data);
    }

    sendDataToLifeDesk(data, retries: number) {
        if (this.usbDevice && this.usbDevice.opened) {
            this.sendUsbData(data);
        } else {
            this.lifeDeskDataInCharacteristic.writeValue(data);
        }
        if (retries > 0) {
            const delay = 0.2;
            setTimeout(() => { this.sendDataToLifeDesk(data, retries - 1); }, delay * 1000);
        }
    }

    resetIdleTimer() {
        clearTimeout(this.idleTimer);
        this.idleTimer = setTimeout(function() {this.idleTimerFired(); }.bind(this), 2000);
    }

    idleTimerFired() {
        console.log('idle timer fired');
        this.status = LifeDeskStatus.NOT_MOVING;
        // TODO post observable that movememnt has stopped
        if (!this.isPairing) {
            const eventName = (this.heightInInches >= environment.STAND_THRESHOLD) ? 'stand' : 'sit';
            this.position = eventName;
            this.logEvent(eventName, this.lifeDeskPeripheralName);
        }
    }

    async logEvent(value: string, key: string, timestamp?: Date) {
        if (this.user) {
            // const nowDate: Date = new Date();
            const now = moment(timestamp);
            console.log('logging ' + value + ' event at ' + now.toISOString());
            const event: Event = new Event();
            event.name = key;
            event.value = value;
            // event.timestamp = nowDate.toISOString();
            event.timestamp = now.format('YYYY-MM-DDTHH:mm:ss.SSS');
            event.version = '1.1';

            const response: any = await this.apiService.logEvent({
                timestamp: event.timestamp,
                name: event.name,
                value: event.value
            }).toPromise();

            const returnedEvent: Event = new Event();
            returnedEvent._id = '' + response[0].eventId ;
            returnedEvent.timestamp = response[0].timestamp;
            returnedEvent.value = response[0].value;
            returnedEvent.duration = response[0].duration;
            returnedEvent.durationadj = response[0].durationAdj;
            returnedEvent.name = response[0].name;
            console.log(returnedEvent);

            let lastEvent: Event;
            if (this.user.lastEvent) {
                lastEvent = this.user.lastEvent;
            } else {
                lastEvent = new Event();
                lastEvent.value = 'away';
            }
            console.log('last event: ' + lastEvent.value + '; returned event: ' + returnedEvent.value);
            if (lastEvent.value === returnedEvent.value) {
                // do nothing, notifications are already set
            } else {
                if (lastEvent.value !== 'away') {
                    // last event had notifications, so cancel them
                    this.cancelAllNotifications();
                }
                if (returnedEvent.value !== 'away') {
                    // new event needs notifications
                    if (returnedEvent.durationadj > 0) {
                        console.log('duration adj:' + returnedEvent.durationadj.toString());
                        // we've corrected a false away event, so we need to reinstate the old notifications
                        // we would not send a welcome back notification here because the away was false
                        this.createNotificationsWithDates(this.user.alertDates);
                    } else {
                        // create new notifications for the returned event
                        console.log('should schedule notification');
                        if (lastEvent.value === 'away') {
                            // welcome back - probably do nothing about that
                            this.scheduleNotificationForEvent(returnedEvent.value);
                        } else {
                            this.scheduleNotificationForEvent(returnedEvent.value);
                        }
                    }
                }
            }
            this.user.lastEvent = returnedEvent;
            this.activityService.isHomeBusy = false;
            this.postureSubject.next(returnedEvent.value);
            /*const store = this.dataStoreService.collection('Events', DataStoreType.Network);
            store.save(Object.assign({}, event)).then((returnedEvent: Event) => {
                console.log('done saving');
                // this.ngZone.run(() => {
                let lastEvent: Event;
                    if (this.user.lastEvent) {
                        lastEvent = this.user.lastEvent;
                    } else {
                        lastEvent = new Event();
                        lastEvent.value = 'away';
                    }
                    console.log('last event: ' + lastEvent.value + '; returned event: ' + returnedEvent.value);
                    if (lastEvent.value === returnedEvent.value) {
                        // do nothing, notifications are already set
                    } else {
                        if (lastEvent.value !== 'away') {
                            // last event had notifications, so cancel them
                            this.cancelAllNotifications();
                        }
                        if (returnedEvent.value !== 'away') {
                            // new event needs notifications
                            if (returnedEvent.durationadj > 0) {
                                console.log('duration adj:' + returnedEvent.durationadj.toString());
                                // we've corrected a false away event, so we need to reinstate the old notifications
                                // we would not send a welcome back notification here because the away was false
                                this.createNotificationsWithDates(this.user.alertDates);
                            } else {
                                // create new notifications for the returned event
                                console.log('should schedule notification');
                                if (lastEvent.value === 'away') {
                                    // welcome back - probably do nothing about that
                                    this.scheduleNotificationForEvent(returnedEvent.value);
                                } else {
                                    this.scheduleNotificationForEvent(returnedEvent.value);
                                }
                            }
                        }
                    }
                    this.user.lastEvent = returnedEvent;
                    this.activityService.isHomeBusy = false;
                    this.postureSubject.next(returnedEvent.value);
                // });
            }).catch((error) => {
                console.log(error);
                // save failed
            });*/
        }
    }

    parseISOString(s) {
        const b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }

    scheduleNotificationForEvent(event) {
        if (this.user) {
            const sitInterval = this.user.preference.sitInterval;
            const standInterval = this.user.preference.standInterval;
            if (sitInterval > 0 && standInterval > 0) {
                let interval;
                if (event === 'sit') {
                    interval = sitInterval;
                } else {
                    interval = standInterval;
                }
                let numberOfAlerts;
                if (interval > 30) {
                    numberOfAlerts = 3;
                } else {
                    numberOfAlerts = 5;
                }

                const alertDates: string[] = [];
                for (let i = 1; i <= numberOfAlerts; i++) {
                    const timeInterval = i * interval * 60 * 1000;
                    const now = new Date();
                    const alertDate = new Date(now.getTime() + timeInterval);
                    alertDates.push(alertDate.toISOString());
                }
                this.user.alertDates = alertDates;
                this.createNotificationsWithDates(alertDates);
            }
        }
    }

    createNotificationsWithDates(alertDates: String[]) {
        // testing
        /*setTimeout(() => {
            if (this.alertObserver) {
                this.alertObserver.next({title: 'Reminder', body: 'Do you want to transition now?'});
            }
        }, 1000);*/

        this.alertTimers = [];
        for (const alertDate of alertDates) {
            const notificationDate = this.parseISOString(alertDate);
            const now = new Date;
            if (notificationDate.getTime() > now.getTime()) {
                const interval = notificationDate.getTime() - now.getTime();
                console.log('setting alert for in ' + Math.round(interval / 1000 / 60 ));
                const alertTimer = setTimeout(() => {
                    if (this.alertObserver) {
                        this.alertObserver.next({title: 'Reminder', body: 'Do you want to transition now?'});
                    }
                }, interval);
                this.alertTimers.push(alertTimer);
            }
        }
    }

    cancelAllNotifications() {
        for (const alertTimer of this.alertTimers) {
            console.log('cancelling alert');
            clearTimeout(alertTimer);
        }
    }

    // movementTimer = setInterval(moveUp, 200);
    // clearInterval(movementTimer);


}
