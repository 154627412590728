

export class Preference {
    _id: string;
    myHeight = 0;
    memoryOne = 0;
    memoryTwo = 0;
    sitInterval = 0;
    standInterval = 0;
    organizationName = '';
    email = '';
    optIn = false;
    birthYear = 0;
    purchaseDate = '';
    lastSurvey = '';
    queuedSurvey = '';
}
