import {NgModule, Injectable, OnDestroy} from '@angular/core';
import { CanActivate, RouterModule, Routes, Router } from '@angular/router';
import { KinveyModule } from 'kinvey-angular-sdk';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { MyDataComponent } from './my-data/my-data.component';
import { SettingsComponent } from './settings/settings.component';
import { RemoteComponent } from './remote/remote.component';
import { NewsComponent } from './news/news.component';
import { SupportComponent } from './support/support.component';
import { OrgAdminComponent } from './org-admin/org-admin.component';
import { SurveyComponent } from './survey/survey.component';
import { ProfileComponent } from './profile/profile.component';
import { UserDataComponent } from './user-data/user-data.component';
import { MyHeightComponent } from './my-height/my-height.component';
import { TransitionsComponent } from './transitions/transitions.component';
import { PairComponent } from './pair/pair.component';
import { UsageChartComponent } from './usage-chart/usage-chart.component';
import { HomeComponent } from './home/home.component';
import { LoginOauthComponent } from './login/login-oauth.component';
import { TestComponentOneComponent } from './test-component-one/test-component-one.component';

import { SDUserService } from './user.service';
import {User} from './models/user';
import {Preference} from './models/preference';
import {Observable, Subscription} from 'rxjs';
import { map } from 'rxjs/operators';
import {UserLayoutComponent} from './user-layout/user-layout.component';
import {AdminLayoutComponent} from './admin-layout/admin-layout.component';
import {ConnectComponent} from './connect/connect.component';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable()
export class NoAuthGuard implements CanActivate, OnDestroy { // read as "guard against no authentication"

    // private user: User = null;
    // private subscription: Subscription;

    constructor(private router: Router, private oAuthService: OAuthService/*, private sdUserService: SDUserService*/) {
        /*this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
        });
        this.subscription.add(userSubscription);*/
    }

    canActivate() {
        // console.log('NoAuthGuard canActivate');

        if (/*this.user*/ this.oAuthService.hasValidAccessToken()) {
            return true;
        }

        // Navigate to the login page
        // this.router.navigate(['/login']);
        this.oAuthService.initCodeFlow();
        return false;
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }
}

@Injectable()
export class NoOauthGuard implements CanActivate {
    canActivate() {
        return true;
    }
}

@Injectable()
export class NoOrgAdminGuard implements CanActivate, OnDestroy {

    private user: User = null;
    private subscription: Subscription;

    constructor(private router: Router, private sdUserService: SDUserService) {
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
        });
        this.subscription.add(userSubscription);
    }
    canActivate() {
        console.log('NoOrgAdminGuard canActivate');
        if (this.user) {
            if (this.user.isOrgAdmin) {
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

@Injectable()
export class NoAdminGuard implements CanActivate, OnDestroy {

    private user: User = null;
    private subscription: Subscription;

    constructor(private router: Router, private sdUserService: SDUserService) {
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
        });
        this.subscription.add(userSubscription);
    }
    canActivate() {
        console.log('NoAdminGuard canActivate');
        if (this.user) {
            if (this.user.isAdmin) {
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

const routes: Routes = [
    {path: '', redirectTo: '/connect', pathMatch: 'full'},
    { path: 'loginoauth', component: LoginOauthComponent},
    {
        path: '',
        component: UserLayoutComponent,
        children: [
            /*{path: '', redirectTo: 'mydata', pathMatch: 'full'},*/
            { path: 'login', component: LoginComponent },
            { path: 'signup', component: SignupComponent },
            { path: 'mydata', component: MyDataComponent, canActivate: [NoAuthGuard] },
            { path: 'settings', component: SettingsComponent },
            { path: 'remote', component: RemoteComponent, canActivate: [NoAuthGuard] },
            { path: 'news', component: NewsComponent, canActivate: [NoAuthGuard] },
            { path: 'support', component: SupportComponent },
            { path: 'survey', component: SurveyComponent, canActivate: [NoAuthGuard]},
            { path: 'profile', component: ProfileComponent, canActivate: [NoAuthGuard]},
            { path: 'myheight', component: MyHeightComponent, canActivate: [NoAuthGuard] },
            { path: 'transitions', component: TransitionsComponent, canActivate: [NoAuthGuard] },
            { path: 'pair', component: PairComponent },
            { path: 'testone', component: TestComponentOneComponent},
            { path: 'home', component: HomeComponent},
            { path: 'connect', component: ConnectComponent},
        ]},
    {
        path: 'admin', redirectTo: '/admin/orgadmin', pathMatch: 'full'
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        children: [
            { path: 'userdata', component: UserDataComponent, canActivate: [NoAdminGuard]},
            { path: 'orgadmin', component: OrgAdminComponent, canActivate: [NoOrgAdminGuard] },
            { path: 'usagechart', component: UsageChartComponent, canActivate: [NoOrgAdminGuard] },
        ]
    }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
    providers: [
      NoAuthGuard,
      NoOrgAdminGuard,
      NoAdminGuard
    ]
})
export class AppRoutingModule {}
