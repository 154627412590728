import {Component, OnInit} from '@angular/core';
import {OAuthService, AuthConfig, OAuthStorage} from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import {CookieService} from 'ngx-cookie-service';
import * as moment from 'moment';
import {SDUserService} from './user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    private sdConfig: AuthConfig = {
        redirectUri: window.location.origin + '/home',
        responseType: 'code',
        clientId: 'spa-v1',
        requireHttps: false,
        scope: 'openid profile email read write',
        loginUrl: 'https://api.standata.com/oauth/authorize',
        tokenEndpoint: 'https://api.standata.com/oauth/token',
        logoutUrl: 'https://api.standata.com/oauth/logout?redirect_uri=' +
            encodeURIComponent(window.location.origin + '/home'),
        oidc: false
    };

    constructor(private oAuthService: OAuthService, private cookieService: CookieService, private sdUserService: SDUserService) {
        window.addEventListener('beforeunload', (event) => {
            event.preventDefault();
            this.sdUserService.getUser().subscribe(user => {
                const userString = JSON.stringify(user);
                this.cookieService.set('standata.user', userString, moment().add( 10, 'years').toDate());
                // this.cookieService.set('lastVisible', moment().toISOString(), moment().add(10, 'years').toDate());
             });
            return undefined;
        });
        this.configure();
    }

    // private idleTimeout = 10;
    // private idleSecondsCounter = 0;

    ngOnInit() {
        /* window.addEventListener( 'click', this.ResetIdleTime.bind(this));
        window.addEventListener( 'mousemove', this.ResetIdleTime.bind(this));
        window.addEventListener( 'mouseenter', this.ResetIdleTime.bind(this));
        window.addEventListener( 'keydown', this.ResetIdleTime.bind(this));
        window.addEventListener( 'scroll', this.ResetIdleTime.bind(this));
        window.addEventListener( 'mousewheel', this.ResetIdleTime.bind(this));
        window.addEventListener( 'touchmove', this.ResetIdleTime.bind(this));
        window.addEventListener( 'touchstart', this.ResetIdleTime.bind(this));
        setInterval(() => { this.CheckIdleTime(); }, 1000); */
    }

    private configure() {
        this.oAuthService.configure(this.sdConfig);
        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
        // this.oauthService.loadDiscoveryDocumentAndTryLogin();
        this.oAuthService.tryLoginCodeFlow()
            .then(() => {
                // console.log('oauth logged in');
            })
            .catch(err => {
                console.log(err);
            });
    }

    /* CheckIdleTime() {
        this.idleSecondsCounter++;
        if (this.idleSecondsCounter >= this.idleTimeout) {
            console.log('Idle for 10 seconds!');
            this.ResetIdleTime();
        }
    }
    ResetIdleTime() {
        this.idleSecondsCounter = 0;
    } */

}
