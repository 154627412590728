import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    // private apiUrl = 'http://ec2-3-89-171-52.compute-1.amazonaws.com/api/';
    private apiUrl = 'https://api.standata.com/api/';
    private oAuthUrl = 'https://api.standata.com/oauth/';

    constructor(private http: HttpClient, private oAuthService: OAuthService) {}

    getUser(): Observable<any> {
        return this.http
            .get(this.apiUrl + 'user')
            .pipe(catchError(this.handleError));
    }

    logOut(): Observable<any> {
        return this.http
            .post(this.oAuthUrl + 'logout', null);
    }

    getUserPref(): Observable<any> {
        return this.http
            .get(this.apiUrl + 'user_pref');
    }

    putUserPref(userPref): Observable<any> {
        return this.http
            .put(this.apiUrl + 'user_pref', userPref);
    }

    putUser(user): Observable<any> {
        return this.http
            .put(this.apiUrl + 'user', user);
    }

    logEvent(event): Observable<any> {
        return this.http
            .post(this.apiUrl + 'event', event);
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 401) {
            if (this.oAuthService) {
                this.oAuthService.logOut(true);
            }
        }
        return throwError('There was an error with the request.');
    }
}
