import { Component, OnInit} from '@angular/core';
import { SupportArticle } from '../models/support-article';
import {RolesService} from '../roles.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

    supportArticles: SupportArticle[];
    selectedSupportArticle: SupportArticle;

    constructor(private rolesService: RolesService) { }

    ngOnInit() {
        this.rolesService.getSupportArticles()
            .subscribe((supportArticles: SupportArticle[]) => {
            this.supportArticles = supportArticles;
        });
    }

    onSelect(supportArticle: SupportArticle): void {
        this.selectedSupportArticle = supportArticle;
    }

    onBackButton() {
        this.selectedSupportArticle = null;
    }

}
