import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NouisliderModule } from 'ng2-nouislider';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { MyDataComponent } from './my-data/my-data.component';
import { SettingsComponent } from './settings/settings.component';
import { NewsComponent } from './news/news.component';
import { RemoteComponent } from './remote/remote.component';
import { SupportComponent } from './support/support.component';
import { SignupComponent } from './signup/signup.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { SupportDetailComponent } from './support-detail/support-detail.component';
import { OrgAdminComponent } from './org-admin/org-admin.component';
import { SurveyComponent } from './survey/survey.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { LoginOauthComponent } from './login/login-oauth.component';

import { SDUserService } from '../app/user.service';
import { UserDataComponent } from './user-data/user-data.component';
import { MyHeightComponent } from './my-height/my-height.component';
import { TransitionsComponent } from './transitions/transitions.component';
import { PairComponent } from './pair/pair.component';
import { CookieService } from 'ngx-cookie-service';
import { UserLayoutComponent } from './user-layout/user-layout.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { TestComponentOneComponent } from './test-component-one/test-component-one.component';
import { TestComponentTwoComponent } from './test-component-two/test-component-two.component';
import { UsageChartComponent } from './usage-chart/usage-chart.component';
import {ConnectComponent} from './connect/connect.component';

import { KinveyModule } from 'kinvey-angular-sdk';

import { OAuthModule} from 'angular-oauth2-oidc';
import {NoSanitizePipe} from "./no-sanitize-pipe";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MyDataComponent,
        SettingsComponent,
        NewsComponent,
        RemoteComponent,
        SupportComponent,
        SignupComponent,
        NewsDetailComponent,
        SupportDetailComponent,
        OrgAdminComponent,
        SurveyComponent,
        ProfileComponent,
        UserDataComponent,
        MyHeightComponent,
        TransitionsComponent,
        PairComponent,
        UserLayoutComponent,
        AdminLayoutComponent,
        TestComponentOneComponent,
        TestComponentTwoComponent,
        UsageChartComponent,
        HomeComponent,
        ConnectComponent,
        LoginOauthComponent,
        NoSanitizePipe
    ],
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NouisliderModule,
        NgCircleProgressModule.forRoot({}),
        ChartsModule,
        KinveyModule.init({
            appKey: 'kid_Bk5xfVVIb',
            appSecret: '65eb6b39c5b141bb92454229eaa3f9d1'
        }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['http://ec2-3-89-171-52.compute-1.amazonaws.com/api', 'https://api.standata.com/'],
                sendAccessToken: true
            }
        })
    ],
    providers: [SDUserService, CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
