import {Component, OnInit, NgZone, OnDestroy} from '@angular/core';
import { formatDate } from '@angular/common';
import { SDUserService } from '../user.service';
import { UserService } from 'kinvey-angular-sdk';
import { Event } from '../models/event';
import {User} from '../models/user';
import {Subscription} from 'rxjs';
import {RolesService} from '../roles.service';
import * as moment from 'moment';
// import {discoverLocalRefs} from '@angular/core/src/render3/context_discovery';
// import { CircleProgressOptions } from 'ng-circle-progress';

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent implements OnInit, OnDestroy {

  selectedIndex: number;
  dailyTransitions: string;
  dailyTransitionsPercent: number;
  percentStanding: number;
  caloriesBurned: string;
  caloriesBurnedPercent: number;
  dailyStanding: string;
  dailyStandingPercent: number;
  selectedChartIndex: number;
  events: Event[];
  private nonZeroDays: any;
  private user: User = null;
  private subscription: Subscription;
  // options = new CircleProgressOptions();

    constructor(private sdUserService: SDUserService, private ngZone: NgZone, private rolesService: RolesService,
                private userService: UserService) { }

    ngOnInit() {
        this.selectedIndex = 0;
        this.nonZeroDays = [];
        this.selectedChartIndex = -1;

        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            console.log('my data got the user');
            this.user = user;
            this.updateData();
        });
        this.subscription.add(userSubscription);

        /*
        this.options.percent = 0;
          this.options.radius = 80;
          this.options.outerStrokeWidth = 24;
          this.options.innerStrokeWidth = 0;
          this.options.outerStrokeColor = '#78C000';
          this.options.innerStrokeColor = '#000000';
          this.options.animation = true;
          this.options.animationDuration = 300;
          this.options.showSubtitle = false;
          this.options.showUnits = false;
          this.options.title = '0';
           */
    }

    ngOnDestroy() {
        console.log('my data destroy');
        this.subscription.unsubscribe();
    }

  onSelectedIndexChanged(index) {
    this.selectedIndex = index;
    this.updateData();
  }

    updateData() {
        if (this.user) {
            let standGoal: number;
            let transitionGoal: number;
            let startDate;
            const endDate = moment();
            let days: number;
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (this.user.preference.standInterval > 0 && this.user.preference.sitInterval > 0) {
                const minutesPerDay = 8 * 60;
                const cycleInterval = this.user.preference.standInterval + this.user.preference.sitInterval;
                const cyclesPerDay = minutesPerDay / cycleInterval;
                transitionGoal = cyclesPerDay * 2;
                standGoal = cyclesPerDay * this.user.preference.standInterval
            } else {
                standGoal = 40;
                transitionGoal = 4;
            }
            switch (this.selectedIndex) {
                case 0:
                    /*// Today
                    startDate = moment();
                    days = 1;*/
                    startDate = moment();
                    break;
                case 1:
                    /*// This Week
                    startDate = this.getFirstDayOfCurrentWeek(today);
                    days  = this.getDaysBetween(startDate, today);*/
                    // 7 days
                    startDate = moment().subtract(6, 'days');
                    break;
                case 2:
                    /*// This Month
                    startDate = this.getFirstDayOfCurrentMonth(today);
                    days = this.getDaysBetween(startDate, today);*/
                    // 30 days
                    startDate = moment().subtract(29, 'days');
                    break;
                default:
                    days = 1;
                    break;
            }
            /*
            console.log('startdate: ' + startDate + ' days: ' + days);
            const dateQuery = new Kinvey.Query();
            dateQuery.greaterThanOrEqualTo('timestamp', startDate.toISOString());
            const store = Kinvey.DataStore.collection<Event>('Events', Kinvey.DataStoreType.Network);
            store.find(dateQuery)
                .subscribe(events => {
                    this.ngZone.run(() => {
                        let sitSeconds = 0;
                        let standSeconds = 0;
                        let count = 0;
                        let groupDate = startDate;
                        let groupCount = 0;
                        this.dataItems = [];
                        for (const event of events) {
                            if (event.value === 'sit') {
                                sitSeconds += event.duration;
                            }
                            if (event.value === 'stand') {
                                standSeconds += event.duration;
                            }
                            if (event.duration >= 600 && (event.value === 'sit' || event.value === 'stand')) {
                                count += 1;
                                const thisDate = this.parseISOString(event.timestamp).setHours(0, 0, 0, 0);
                                if (thisDate !== groupDate) {
                                    // TODO fill in gap between dates
                                    this.dataItems.push({date: formatDate(groupDate, 'yyyy-MM-dd', 'en-US'),
                                        description: 'transitions', value: groupCount});
                                    groupDate = thisDate;
                                    groupCount = 0;
                                }
                                groupCount += 1;
                            }
                        }
                        this.dataItems.push({date: formatDate(groupDate, 'yyyy-MM-dd', 'en-US'),
                            description: 'transitions', value: groupCount});
                        console.log('count: ' + count);

                        // transitions
                        const dailyTransitions = count / days;
                        this.dailyTransitions = dailyTransitions.toFixed(0);
                        this.dailyTransitionsPercent = Math.floor((dailyTransitions / transitionGoal) * 100);
                        console.log('daily transitions percent: ' + this.dailyTransitionsPercent);

                        // percent standing
                        const sitMinutes = sitSeconds / 60;
                        const standMinutes = standSeconds / 60;
                        const totalMinutes = standMinutes + sitMinutes;
                        this.percentStanding = (totalMinutes > 0) ? (standMinutes / totalMinutes) * 100 : 0;

                        // calories burned
                        const caloriesBurned = standMinutes * 0.7;
                        const caloriesBurnedGoal = standGoal * 0.7 * days;
                        this.caloriesBurnedPercent = (caloriesBurnedGoal > 0) ? (caloriesBurned / caloriesBurnedGoal) * 100 : 0;

                        // daily standing
                        const averageStandTime = standMinutes / days;
                        this.dailyStanding = averageStandTime.toFixed(0);
                        this.dailyStandingPercent = (totalMinutes > 0) ? (standMinutes / totalMinutes) * 100 : 0;
                    });
                });
             */
            const startDateString = startDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
            const endDateString = endDate.format('YYYY-MM-DD') + 'T23:59:59.999Z';
            this.rolesService.getEventStatsByUserCollection2([this.userService.getActiveUser()], startDateString, endDateString)
                .subscribe((userstats) => {
                    const myuserstat = userstats[0];
                    if (myuserstat) {
                        console.log(myuserstat);

                        // transitions per active day
                        let transitions;
                        const activeDays = myuserstat['days'].filter(x => x.transitions > 0);
                        if (activeDays.length > 0) {
                            transitions = activeDays.reduce(function(dayTransitions, day) {
                                return dayTransitions + day['transitions'];
                            }, 0);
                            const dailyTransitions = transitions / activeDays.length ;
                            this.dailyTransitions = dailyTransitions.toFixed(0);
                            this.dailyTransitionsPercent = Math.floor((dailyTransitions / transitionGoal) * 100);
                        } else {
                            transitions = 0;
                            this.dailyTransitions = '0';
                            this.dailyTransitionsPercent = 0;
                        }

                        // Percent Standing
                        const sitTime: number = myuserstat['sittime'];
                        const standTime: number = myuserstat['standtime'];
                        this.nonZeroDays = myuserstat['days'].filter(x => x.sittime > 0 && x.standtime > 0);
                        const nonZeroDayCount = this.nonZeroDays.length;
                        if (nonZeroDayCount > 0) {
                            const totalTime = sitTime + standTime;
                            this.percentStanding = Math.floor((standTime / totalTime) * 100);
                        } else {
                            this.percentStanding = 0;
                        }

                        // Calories Burned
                        const transitionCalories = transitions * 12;
                        const standMinutes = standTime / 60.0;
                        const standCalories = Math.floor(standMinutes *  0.7);
                        const totalCalories = transitionCalories + standCalories;
                        const caloriesBurnedGoal = ((transitionGoal * 12) + (standGoal * 0.7)) * nonZeroDayCount;
                        if (caloriesBurnedGoal > 0) {
                            this.caloriesBurned = totalCalories.toFixed(0);
                            this.caloriesBurnedPercent = Math.floor(totalCalories / caloriesBurnedGoal);
                        } else {
                            this.caloriesBurned = '0';
                            this.caloriesBurnedPercent = 0;
                        }

                        // Daily Standing
                        let averageStandtime;
                        if (nonZeroDayCount > 0) {
                            averageStandtime = standMinutes / nonZeroDayCount;
                        } else {
                            averageStandtime = 0;
                        }
                        if (standGoal > 0) {
                            this.dailyStanding = averageStandtime.toFixed(0);
                            this.dailyStandingPercent = Math.floor((averageStandtime / standGoal) * 100);
                        } else {
                            this.dailyStanding = '0';
                            this.dailyStandingPercent = 0;
                        }
                    } else {
                        this.dailyTransitions = '0';
                        this.dailyTransitionsPercent = 0;
                        this.percentStanding = 0;
                        this.caloriesBurned = '0';
                        this.caloriesBurnedPercent = 0;
                        this.dailyStanding = '0';
                        this.dailyStandingPercent = 0;
                    }
                });
        }
    }

  getFirstDayOfCurrentMonth(d) {
      return new Date(d.getFullYear(), d.getMonth(), 1);
  }
  getFirstDayOfCurrentWeek(d) {
      const day = d.getDay();
      return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? - 7 : 0) - day );
  }
    getDaysBetween(d1, d2) {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    return Math.floor((d2 - d1) / millisecondsPerDay) + 1;
  }

  onSelectedChartIndexChanged(index) {
      this.selectedChartIndex = index;
  }

    parseISOString(s) {
        const b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }
}
