import {AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { User } from '../models/user';
import { SDUserService } from '../user.service';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-my-height',
    templateUrl: './my-height.component.html',
    styleUrls: ['./my-height.component.scss']
})
export class MyHeightComponent implements OnInit, AfterViewInit, OnDestroy {

    private myHeight: number;
    myHeightLabel: string;
    sittingHeight: number;
    standingHeight: number;
    sittingHeightLabel: string;
    standingHeightLabel: string;
    user: User;
    private ruler;
    private lastScrollUpdate = -1;
    private shouldFireOnScroll = true;
    protected confirmMemorySetModalBody: string;
    private subscription: Subscription;

    constructor(private sdUserService: SDUserService, private location: Location, private ngZone: NgZone,
                private modalService: NgbModal) { }

    @ViewChild('rulerVar', { static: true }) el: ElementRef;
    @ViewChild('confirmMemorySetModal', { static: true }) private confirmMemorySetModal;

    ngOnInit() {
        console.log('my height on init');
        this.myHeight = 58;
        this.setHeightLabel();
        this.setSuggestedValuesForHeight();
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
            console.log(this.user);
            if (user.preference.myHeight) {
                if (user.preference.myHeight > 0) {
                    console.log('setting my height');
                    this.myHeight = user.preference.myHeight;
                    this.setHeightLabel();
                    this.setScrollPosition();
                    this.setSuggestedValuesForHeight();
                }
            }
        });
        this.subscription.add(userSubscription);
    }

    ngAfterViewInit() {
        console.log('after view init');
        this.ruler = this.el.nativeElement;
        setTimeout(() => {
            this.setScrollPosition();
        }, 100);
    }

    ngOnDestroy() {
        console.log('my height on destroy');
        this.user.preference.myHeight = this.myHeight;
        this.sdUserService.saveUser(this.user);
        this.subscription.unsubscribe();
    }

    didScroll(event) {
        if (this.shouldFireOnScroll) {
            const scrollStateHandler = () => {
                const currentTime = (new Date()).getTime();
                if ((currentTime - this.lastScrollUpdate) > 100 ) {
                    this.lastScrollUpdate = -1;
                    // on scroll end
                    // adjust for rounding
                    this.setScrollPosition();
                } else {
                    setTimeout(scrollStateHandler, 100);
                }
            };
            const ruler = event.srcElement;
            this.myHeight = 58.0 + Math.round(ruler.scrollLeft / 18.0);
            this.setHeightLabel();
            this.setSuggestedValuesForHeight();
            if (this.lastScrollUpdate === -1) {
                // on scroll start
                setTimeout(scrollStateHandler, 100);
            }
            this.lastScrollUpdate = (new Date()).getTime();
        } else {
            this.shouldFireOnScroll = true;
        }
    }

    setScrollPosition() {
        const offset = (this.myHeight - 58) * 18;
        if (this.ruler) {
            console.log('setting scroll position to ' + offset.toString());
            this.shouldFireOnScroll = false;
            this.ruler.scrollLeft = offset;
        }
    }

    setHeightLabel() {
        const feet = Math.floor(this.myHeight / 12);
        const inches = this.myHeight % 12;
        this.myHeightLabel = feet.toString() + ' ft, ' + inches.toString() + ' in';
    }

    setSuggestedValuesForHeight() {
        const startingHeight = 58;
        const heightMatrix = [
            [58, 21.5, 35.3, 41.0, 53.9],
            [59, 21.7, 35.9, 41.5, 54.8],
            [60, 21.9, 36.5, 42.0, 55.7],
            [61, 22.3, 37.1, 42.5, 56.6],
            [62, 22.6, 37.8, 43.0, 57.6],
            [63, 22.9, 38.2, 43.3, 58.6],
            [64, 23.0, 38.3, 44.0, 59.4],
            [65, 23.8, 39.6, 44.9, 60.4],
            [66, 24.4, 40.1, 45.6, 61.2],
            [67, 25.3, 40.7, 46.5, 62.2],
            [68, 26.4, 41.9, 47.8, 63.6],
            [69, 27.0, 42.5, 48.5, 64.4],
            [70, 27.8, 43.3, 49.4, 65.4],
            [71, 28.6, 43.5, 49.8, 66.1],
            [72, 29.3, 44.3, 50.7, 67.0],
            [73, 30.5, 45.2, 51.6, 67.9],
            [74, 31.3, 46.1, 52.5, 68.6],
            [75, 32.1, 47.0, 53.4, 69.3],
            [76, 32.9, 48.1, 54.5, 70.1],
            [77, 33.7, 49.2, 55.4, 71.0],
            [78, 34.3, 49.5, 56.3, 71.7],
            [79, 34.8, 49.8, 57.2, 72.4],
            [80, 35.2, 50.4, 58.1, 74.1],
            [81, 35.6, 51.0, 59.0, 74.8]
        ];
        const heights = heightMatrix[this.myHeight - startingHeight];
        this.sittingHeight = heights[1];
        this.standingHeight = heights[2];
        this.sittingHeightLabel = this.sittingHeight.toFixed(1) + '"';
        this.standingHeightLabel = this.standingHeight.toFixed(1) + '"';
    }

    setRecommendedHeight() {
        this.confirmMemorySetModalBody = 'Do you want to set your saved sitting position to ' +
            this.sittingHeight.toFixed(1) + '" and your saved standing position to ' +
            this.standingHeight.toFixed(1) + '"?';
        this.ngZone.run(() => {
            this.modalService.open(this.confirmMemorySetModal).result.then((result) => {
                this.user.preference.memoryOne = this.sittingHeight;
                this.user.preference.memoryTwo = this.standingHeight;
                this.sdUserService.saveUser(this.user);
            }).catch((error) => {
                // do not save
            });
        });
    }

    backPressed() {
        this.location.back();
    }

}
