import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { SDUserService } from '../user.service';
import {LifeDeskService} from '../life-desk.service';
import {Location} from '@angular/common';

@Component({
    selector: 'app-transitions',
    templateUrl: './transitions.component.html',
    styleUrls: ['./transitions.component.scss']
})

export class TransitionsComponent implements OnInit {

    transitions: TransitionPlan[];
    selectedIndex: number;
    user: User;
    testTransitionPlan: TransitionPlan = {name : '2/4', description: 'Stand 2 minutes, sit 4 minutes', difficulty: 'Test'};
    constructor(private sdUserService: SDUserService, private lifeDeskService: LifeDeskService, private location: Location) { }

    ngOnInit() {
        this.selectedIndex = 0;
        const transitionPlan0: TransitionPlan = {name : 'None', description: '', difficulty: ''};
        const transitionPlan1: TransitionPlan = {name : '10/50', description: 'Stand 10 minutes, sit 50 minutes',
            difficulty: 'Beginner'};
        const transitionPlan2: TransitionPlan = {name : '20/220', description: 'Stand 20 minutes, sit 220 minutes',
            difficulty: 'After Meals'};
        const transitionPlan3: TransitionPlan = {name : '20/40', description: 'Stand 20 minutes, sit 40 minutes',
            difficulty: 'Intermediate'};
        const transitionPlan4: TransitionPlan = {name : '30/30', description: 'Stand 30 minutes, sit 30 minutes',
            difficulty: 'Advanced'};
        const transitionPlan5: TransitionPlan = {name : '10/20', description: 'Stand 10 minutes, sit 20 minutes',
            difficulty: 'Expert'};
        this.transitions = [transitionPlan0, transitionPlan1, transitionPlan2, transitionPlan3, transitionPlan4, transitionPlan5];
        this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
            if (user.preference.sitInterval && user.preference.standInterval) {
                if (user.preference.sitInterval === 50 && user.preference.standInterval === 10) {
                    this.selectedIndex = 1;
                }
                if (user.preference.sitInterval === 220 && user.preference.standInterval === 20) {
                    this.selectedIndex = 2;
                }
                if (user.preference.sitInterval === 40 && user.preference.standInterval === 20) {
                    this.selectedIndex = 3;
                }
                if (user.preference.sitInterval === 30 && user.preference.standInterval === 30) {
                    this.selectedIndex = 4;
                }
                if (user.preference.sitInterval === 20 && user.preference.standInterval === 10) {
                    this.selectedIndex = 5;
                }
                if (user.isAdmin) {
                    if (user.preference.sitInterval === 4 && user.preference.standInterval === 2) {
                        this.selectedIndex = 6;
                    }
                    if (this.transitions.length < 7) {
                        this.transitions.push(this.testTransitionPlan);
                    }
                } else {
                    if (this.transitions.length > 6)  {
                        this.transitions.pop();
                    }
                }
            }
        });
    }

    onSelectedIndexChanged(index: number) {
        this.selectedIndex = index;
        switch (index) {
            case 0:
                this.user.preference.sitInterval = 0;
                this.user.preference.standInterval = 0;
                break;
            case 1:
                this.user.preference.sitInterval = 50;
                this.user.preference.standInterval = 10;
                break;
            case 2:
                this.user.preference.sitInterval = 220;
                this.user.preference.standInterval = 20;
                break;
            case 3:
                this.user.preference.sitInterval = 40;
                this.user.preference.standInterval = 20;
                break;
            case 4:
                this.user.preference.sitInterval = 30;
                this.user.preference.standInterval = 30;
                break;
            case 5:
                this.user.preference.sitInterval = 20;
                this.user.preference.standInterval = 10;
                break;
            case 6:
                this.user.preference.sitInterval = 4;
                this.user.preference.standInterval = 2;
                break;
            default:
                this.user.preference.sitInterval = 0;
                this.user.preference.standInterval = 0;
                break;
        }
        this.sdUserService.saveUser(this.user);
        this.lifeDeskService.cancelAllNotifications();
        this.lifeDeskService.scheduleNotificationForEvent(this.lifeDeskService.position);
    }

    backPressed() {
        this.location.back();
    }

}

class TransitionPlan {
    name: string;
    description: string;
    difficulty: string;
}


