import {Component, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {LifeDeskService} from '../life-desk.service';

@Component({
    selector: 'app-connect',
    templateUrl: './connect.component.html',
    styleUrls: ['./connect.component.scss']
})

export class ConnectComponent implements OnInit {

    constructor(private cookieService: CookieService, private router: Router, private lifeDeskService: LifeDeskService) { }

    ngOnInit() {
        if (this.cookieService.check('deskType')) {
            this.router.navigate(['/home']);
        }
    }

    search() {
        this.router.navigate(['/pair']);
    }

    setUniversal() {
        this.lifeDeskService.setDeskType('Universal');
        this.router.navigate(['/home']);
    }

    setUSB() {
        this.lifeDeskService.connectLifeDeskUsb();
        this.lifeDeskService.setDeskType('USB');
    }
}

