import {Component, OnInit, NgZone, OnDestroy, ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';
// import { UserService } from 'kinvey-angular-sdk';
import { CookieService } from 'ngx-cookie-service';
import { LifeDeskService } from '../life-desk.service';
import { SDUserService } from '../user.service';
import { Subscription } from 'rxjs';
import {User} from '../models/user';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

    private notificationOne: Notification;
    private notificationOneTimer;
    protected pairedDesk: string;
    protected pairedDeskName: string;
    protected isShowingDeviceDetails: boolean;
    private subscription: Subscription;
    private currentMode = 'LifeDesk';
    protected user: User = null;

    constructor(private router: Router, private ngZone: NgZone, private cookieService: CookieService,
                protected lifeDeskService: LifeDeskService, private sdUserService: SDUserService,
                private cd: ChangeDetectorRef, private oAuthService: OAuthService /*, private userService: UserService*/) { }

    ngOnInit() {
        this.setDeskName();
        this.subscription = new Subscription();
        const connectedSubscription = this.lifeDeskService.connectedObservable().subscribe((connected) => {
            this.setDeskName();
        });
        this.subscription.add(connectedSubscription);
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            console.log('settings got the user');
            this.user = user;
            this.cd.detectChanges();
        });
        this.subscription.add(userSubscription);
        this.isShowingDeviceDetails = false;

        if (this.cookieService.check('deskType')) {
            const deskType = this.cookieService.get('deskType');
            if (deskType === 'Universal') {
                this.currentMode = 'Universal';
            }
        }
    }

    setDeskName() {
        if (this.lifeDeskService.isConnected) {
            if (this.lifeDeskService.lifeDeskPeripheralName !== '') {
                this.pairedDesk = this.lifeDeskService.lifeDeskPeripheralName;
                // TODO fetch desk name from Kinvey
                this.pairedDeskName = '';
            } else {
                this.pairedDesk = 'Connected';
                this.pairedDeskName = '';
            }
        } else {
            this.pairedDesk = 'None';
            this.pairedDeskName = 'Pair New Desk';
        }
    }

    logOut() {
        sessionStorage.setItem('id_token', 'nothing');
        this.oAuthService.logOut(false);
        this.cookieService.delete('standata.user');
        this.sdUserService.refreshUser();
        /*this.userService.logout()
            .then(() => {
                this.cookieService.delete('standata.user');
                this.sdUserService.refreshUser();
                this.ngZone.run(() => {
                  // this.router.navigate(['/login']);
                });
          });*/
    }

    forget() {
        this.cookieService.delete('LifeDeskName');
        this.lifeDeskService.disconnectLifeDesk();
    }

    showNotification(title, body) {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            alert('This browser does not support system notifications');
            // Let's check whether notification permissions have already been granted
        } else if ((Notification as any).permission === 'granted') {
            // If it's okay let's create a notification
            const img = '/assets/images/ldicon.png';
            const notification = new Notification(title,
                { body: body, icon: img });
        } else if ((Notification as any).permission !== 'denied') { // Otherwise, we need to ask the user for permission
            Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === 'granted') {
                    const img = '/assets/images/ldicon.png';
                    const notification = new Notification(title,
                        { body: body, icon: img });
                }
            });
        }
    }

    showDeviceDetails() {
        if (this.lifeDeskService.isConnected) {
            this.isShowingDeviceDetails = true;
        } else {
            this.router.navigate(['/pair']);
        }
    }

    toggleUniversal() {
        if (this.currentMode === 'Universal') {
            this.currentMode = 'LifeDesk';
            const now = new Date();
            const future = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
            this.cookieService.set('deskType', 'LifeDesk', future );
        } else {
            this.currentMode = 'Universal';
            const now = new Date();
            const future = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
            this.cookieService.set('deskType', 'Universal', future );
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        console.log('settings on destroy');
    }

}
