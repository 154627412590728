import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
    NgZone,
    AfterViewChecked, ComponentFactoryResolver, ViewContainerRef
} from '@angular/core';
import {LifeDeskService, LifeDeskStatus} from '../life-desk.service';
import {Subscription} from 'rxjs';
import {ElementRef} from '@angular/core';
import {User} from '../models/user';
import {SDUserService} from '../user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../environments/environment';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {ActivityService} from '../activity.service';
import {MyDataComponent} from '../my-data/my-data.component';
import {SupportComponent} from '../support/support.component';
import {PairComponent} from '../pair/pair.component';
import {TransitionsComponent} from '../transitions/transitions.component';
import {MyHeightComponent} from '../my-height/my-height.component';
import {ProfileComponent} from '../profile/profile.component';
import {ScreenSizeService} from '../screen-size.service';
import {OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {ApiService} from '../api-service';
import {DidYouKnows} from '../../assets/ts/did-you-know';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    protected heightLabel: string;
    private lastScrollUpdate = -1;
    private targetHeight: number;
    private wheel;
    private scrollCompletionTimer;
    private subscription: Subscription;
    protected isPlaying = false;
    protected nextTransition = 'Next:';
    protected isUniversalMode;
    private timer;
    private user: User = null;
    protected postureLabel = 'Away';
    protected postureConfirmationMessage;
    private memoryButtonDownTime: Date;
    protected confirmMemorySetModalBody: string;
    private shouldFireOnScroll = true;
    protected alertTitle: string;
    protected alertBody: string;
    protected brand: string;
    protected brandAlt: string;
    protected isUiBlocked = false;
    private isOnboardingStarted = false;
    protected isBusy = false;
    private isDestroyed = false;
    private dynamicComponents: any[];
    private selectedComponent = 0;
    private shouldShowHome;
    private screenSize = 0;
    private didYouKnows = DidYouKnows;
    private didYouKnowText: string;

    constructor(protected lifeDeskService: LifeDeskService, protected sdUserService: SDUserService,
                private modalService: NgbModal, private cd: ChangeDetectorRef, private cookieService: CookieService,
                private ngZone: NgZone, private router: Router, private activityService: ActivityService,
                private componentFactoryResolver: ComponentFactoryResolver, private screenSizeService: ScreenSizeService,
                private oAuthService: OAuthService) { }

    @ViewChild('wheelVar', { static: false }) el: ElementRef;
    @ViewChild('postureModal', { static: true }) private postureModal;
    @ViewChild('postureConfirmationModal', { static: true }) private postureConfirmationModal;
    @ViewChild('confirmMemorySetModal', { static: true }) private confirmMemorySetModal;
    @ViewChild('alertModal', { static: true }) private alertModal;
    @ViewChild('needsLoginModal', { static: true }) private needsLoginModal;
    @ViewChild('deskHeightPopup', { static: false }) private deskHeightPopup;
    @ViewChild('memorySettingsPopup', { static: false }) private memorySettingsPopup;
    @ViewChild('transitionPlanPopup', { static: false }) private transitionPlanPopup;
    @ViewChild('posturePopup', { static: false }) private posturePopup;
    @ViewChild('columnTwoContainer', { read: ViewContainerRef, static: false }) colmnTwoContainer: ViewContainerRef;
    @ViewChild('columnThreeContainer', { read: ViewContainerRef, static: false }) columnThreeContainer: ViewContainerRef;

    @ViewChild('tp', { static: true }) private tp;
    @ViewChild('mh', { static: true }) private mh;
    @ViewChild('pr', { static: true }) private pr;
    private popUps: any[];

    openExclusivePopuUps(e, popUpToOpen) {
        e.stopPropagation(); // make sure containing clickable item is not clicked
        for (const popUp of this.popUps) {
            if (popUp === popUpToOpen) {
                if (!popUp.isOpen()) {
                    popUp.open();
                }
            } else {
                if (popUp.isOpen()) {
                    popUp.close();
                }
            }
        }
    }

    ngOnInit() {
        if (this.cookieService.check('deskType')) {
            const deskType = this.cookieService.get('deskType');
            if (deskType === 'Universal') {
                this.isUniversalMode = true;
                this.brand = '/assets/images/standata.svg';
                this.brandAlt = 'StanData Logo';
            } else {
                this.isUniversalMode = false;
                if (this.cookieService.check('Brand')) {
                    this.brand = environment.BRAND_URL + this.cookieService.get('Brand');
                    this.brandAlt = '';
                } else {
                    this.brand = '/assets/images/lifedesk.svg';
                    this.brandAlt = 'LifeDesk Logo';
                }
            }
        }

        // ========= dynamic components =========
        this.dynamicComponents = [MyDataComponent, SupportComponent, PairComponent, TransitionsComponent,
            MyHeightComponent, ProfileComponent];

        // ========= subscriptions =========
        this.subscription = new Subscription();
        const heightSubscription = this.lifeDeskService.heightObservable().subscribe((heightInInches) => {
            console.log('observable height in inches: ' + heightInInches );
            this.targetHeight = heightInInches;
            this.setHeightDisplay(true);
        });
        this.subscription.add(heightSubscription);

        /*const targetHeightSubscription = this.lifeDeskService.targetHeightObservable().subscribe( heightInInches => {
            this.targetHeight = heightInInches;
            this.setHeightDisplay(true);
        });
        this.subscription.add(targetHeightSubscription);*/

        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            console.log('home got the user');
            this.user = user;
            this.updateLoginStatus();
        });
        this.subscription.add(userSubscription);

        /*const oAuthSubscription = this.oAuthService.events.subscribe((oAuthEvent: OAuthEvent) => {
            if (oAuthEvent.type === 'token_received' ||
                oAuthEvent.type === 'token_refreshed' ||
                oAuthEvent.type === 'logout') {
                this.updateLoginStatus();
            }
        });
        this.subscription.add(oAuthSubscription);1*/

        const postureSubscription = this.lifeDeskService.postureObservable().subscribe( posture => {
            this.setDisplayForPosture(posture);
            this.cd.detectChanges(); // is this necessary?
        });
        this.subscription.add(postureSubscription);

        const activitySubscription = this.activityService.isHomeBusyObservable().subscribe( isHomeBusy => {
            this.isBusy = isHomeBusy;
            this.cd.detectChanges();  // is this necessary?
        });
        this.subscription.add(activitySubscription);

        const screenSizeSubscription = this.screenSizeService.getScreenSize().subscribe( size => {
            this.screenSize = size;
            console.log('showing home');
            this.shouldShowHome = true;
            if (size < 2) {
                // single column
                this.colmnTwoContainer.clear();
            } else if (size < 3) {
                // two column
                this.selectedComponent = -1;
                this.colmnTwoContainer.clear();
                this.columnThreeContainer.clear();
            } else {
                // three column
                if (this.selectedComponent === -1 || this.selectedComponent === 0) {
                    this.selectedComponent = (this.user) ? 0 : 1;
                }
                this.columnThreeContainer.clear();
                this.loadComponent(this.selectedComponent);
            }
            this.cd.detectChanges(); // avoids the ExpressionChangedAfterItHasBeenCheckedError
        });
        this.subscription.add(screenSizeSubscription);

        const didYouKnowIndex = Math.floor(Math.random() * this.didYouKnows.length);
        this.didYouKnowText = this.didYouKnows[didYouKnowIndex].text;
    }

    ngAfterViewInit(): void {
        console.log('after view init');
        const connectedSubscription = this.lifeDeskService.connectedObservable().subscribe(isConnected => {
            this.updateConnectionStatus();
        });
        this.subscription.add(connectedSubscription);
        this.popUps = [this.tp, this.mh, this.pr];
    }

    ngAfterViewChecked() {

    }

    ngOnDestroy() {
        console.log('on destroy');
        this.isDestroyed = true;
        // this.cd.detach();
        this.subscription.unsubscribe();
        clearInterval(this.timer);
    }

    didScroll(event) {
        if (this.shouldFireOnScroll) {
            console.log('did scroll fired');
            const scrollStateHandler = () => {
                const currentTime = (new Date()).getTime();
                if ((currentTime - this.lastScrollUpdate) > 100 ) {
                    this.lastScrollUpdate = -1;
                    // on scroll end
                    this.setHeightDisplay(true); // adjust for rounding;
                    // fire scroll idle timer
                    this.scrollCompletionTimer = setTimeout(() => {
                        console.log('scroll complete');
                        // TODO set busy
                        const subscription: Subscription = this.lifeDeskService.moveToTargetHeightObservable(this.targetHeight)
                            .subscribe(
                                height => {
                                    this.setHeightDisplay(true);
                                    if (this.isOnboardingStarted/* && !this.didShowMemoryTip*/) {
                                        /* this.didShowMemoryTip = true; */
                                        this.isUiBlocked = true;
                                        this.memorySettingsPopup.open();
                                    }
                                },
                                error => {
                                    console.log(error);
                                    subscription.unsubscribe();
                                },
                                () => {
                                    console.log('complete');
                                    subscription.unsubscribe();
                                }
                            );
                    }, 500);
                } else {
                    setTimeout(scrollStateHandler, 100);
                }
            };
            const wheel = event.srcElement;
            const inches = wheel.scrollTop / 100 + 22;
            const newTargetHeight = Math.round(inches * 10) / 10.0;
            this.targetHeight = newTargetHeight;
            this.setHeightDisplay(false);
            if (this.lastScrollUpdate === -1) {
                // on scroll start
                clearTimeout(this.scrollCompletionTimer);
                setTimeout(scrollStateHandler, 100);
            }
            this.lastScrollUpdate = (new Date()).getTime();
        } else {
            this.shouldFireOnScroll = true;
        }
    }

    setHeightDisplay(shouldUpdateScrollView: boolean) {
        // label
        this.heightLabel = this.targetHeight.toFixed(1) + '"';

        // desk

        // scroll view
        if (shouldUpdateScrollView && this.wheel) {
            const offset = (this.targetHeight - 22) * 100;
            if (this.wheel.scrollTop !== offset) {
                this.shouldFireOnScroll = false;
                this.wheel.scrollTop = offset;
            }
        }
    }

    setDisplayForPosture(posture: string) {
        if (posture === 'sit') {
            this.isPlaying = true;
            this.postureLabel = 'Sitting';
        } else if (posture === 'stand') {
            this.isPlaying = true;
            this.postureLabel = 'Standing';
        } else {
            this.isPlaying = false;
            this.postureLabel = 'Away';
        }
    }

    posturePlayPause() {
        if (this.user) {
            if (this.isPlaying) {
                this.pauseUniversal();
            } else {
                this.playUniversal();
            }
        }
    }

    pauseUniversal() {
        // this.setDisplayForPosture('away');
        this.isBusy = true;
        this.lifeDeskService.logEvent('away', 'Universal');
    }

    playUniversal() {
        this.modalService.open(this.postureModal).result.then((result) => {
            // make sure user is up to date before saving
            // this.setDisplayForPosture(result);
            this.isBusy = true;
            this.lifeDeskService.logEvent(result, 'Universal');
        }).catch((error) => {
            // do nothing
        });
    }

    parseISOString(s) {
        const b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }

    updateConnectionStatus() {
        console.log('update connection status');
        if (this.lifeDeskService.isConnected && !this.isUniversalMode) {
            if (this.el) { // scroll wheel element can be undefined in 2 column view
                this.wheel = this.el.nativeElement;
                // TODO repeated code
                const offset = (this.targetHeight - 22) * 100;
                // this.ngZone.run(() => {
                if (this.wheel.scrollTop !== offset) {
                    setTimeout(() => {
                        this.shouldFireOnScroll = false;
                        this.wheel.scrollTop = offset;
                    }, 100);
                }
                // });

                // onboarding
                if (!this.cookieService.check('isOnboardingComplete')) {
                    this.deskHeightPopup.open();
                    this.isUiBlocked = true;
                    this.isOnboardingStarted = true;
                    this.cd.detectChanges();
                }
            }
        } else {
            this.wheel = null;
            if (this.isUniversalMode) {
                if (!this.cookieService.check('isOnboardingComplete')) {
                    if (this.user && this.user.hasTransitionPlan) {
                        this.posturePopup.open();
                    } else {
                        this.transitionPlanPopup.open();
                    }
                    this.isUiBlocked = true;
                    this.isOnboardingStarted = true;
                    this.cd.detectChanges();
                }
            }
        }
    }

    updateLoginStatus() {
        if (this.user) {
            if (this.user.lastEvent && this.isUniversalMode) {
                this.setDisplayForPosture(this.user.lastEvent.value);
            }
            this.timer = setInterval(() => {
                if (!this.isDestroyed) {
                    let remaining: number;
                    if (this.user.alertDates && this.user.alertDates.length > 0) {
                        const reminderDate: Date = this.parseISOString(this.user.alertDates[0]);
                        remaining = (reminderDate.getTime() - (new Date()).getTime()) / 1000;
                    } else {
                        remaining = 0;
                    }
                    if (remaining > 0) {
                        const minutes = Math.floor(remaining / 60);
                        const seconds = Math.floor( remaining % 60);
                        const secondsPadded = '0' + seconds.toString();
                        this.nextTransition = 'Next: ' + minutes.toString() + ':' +
                            secondsPadded.substr(secondsPadded.length - 2);
                    } else {
                        this.nextTransition = 'Next: Now';
                    }
                    this.cd.detectChanges();
                }
            }, 1000);
        } else {
           clearInterval(this.timer);
        }
    }

    transitionNow() {
        if (!this.isBusy) {
            if (this.user) {
                if (this.isUniversalMode) {
                    if (this.user.hasTransitionPlan && this.isPlaying) {
                        this.showPostureChangeConfirmationAlert();
                    } else if (!this.user.hasTransitionPlan) {
                        this.showNoTransitionPlanAlert();
                    }
                } else {
                    if (this.user.hasTransitionPlan && this.user.hasBothMemoryPositionsSaved) {
                        if (this.lifeDeskService.isConnected) {
                            this.moveToMemoryPosition((this.targetHeight >= environment.STAND_THRESHOLD) ? 1 : 2);
                        } else {
                            this.showAlert('Transition Plan', 'You are not currently connected to a desk. ' +
                                'Connect to a compatible Bluetooth desk to transition automatically.');
                        }
                    } else {
                        if (!this.user.hasTransitionPlan) {
                            this.showNoTransitionPlanAlert();
                        } else {
                            this.showAlert('Transition Plan', 'You do not have memory settings saved for ' +
                                'both sit and stand. Save your preferred sit and stand heights to transition automatically.');
                        }
                    }
                }
            } else {
                this.showNeedsLoginAlert('Transition Plan', 'Set goals and get reminders to get the most ' +
                    'out of your desk. This feature requires a user account. Would you like to log in or sign up now?');
            }
        }
    }

    showPostureChangeConfirmationAlert() {
        let message: string;
        let event: string;
        if (this.user.lastEvent.value === 'stand') {
            message = 'Please confirm that you have changed your posture to sitting.';
            event = 'sit';
        } else if (this.user.lastEvent.value === 'sit') {
            message = 'Please confirm that you have changed your posture to standing.';
            event = 'stand';
        } else {
            // this should never happen
        }
        this.postureConfirmationMessage = message;
        this.modalService.open(this.postureConfirmationModal).result.then((result) => {
            // make sure user is up to date before saving
            if (result === 'confirmed') {
                // this.setDisplayForPosture(event);
                this.isBusy = true;
                // this.ngZone.run(() => {
                    this.lifeDeskService.logEvent(event, 'Universal');
                // });
            }
            if (result === 'away') {
                this.pauseUniversal();
            }
        }).catch((error) => {
            // do nothing
        });
    }

    moveToMemoryPosition(position: number) {
        let target: number;
        switch (position) {
            case 1:
                target = this.user.preference.memoryOne;
                break;
            case 2:
                target = this.user.preference.memoryTwo;
                break;
            default:
                target = 0;
                break;
        }
        if (target > 0) {
            this.targetHeight = target;
            // this.setHeightDisplay(true); // lifeDeskSerice will do this for us
            // TODO setBusy;
            const subscription: Subscription = this.lifeDeskService.moveToTargetHeightObservable(this.targetHeight)
                .subscribe(
                    height => {
                        this.setHeightDisplay(true);
                    },
                    error => {
                        console.log(error);
                        subscription.unsubscribe();
                    },
                    () => {
                        console.log('complete');
                        subscription.unsubscribe();
                    }
                );
        }
    }

    startMemoryClick(position) {
        this.memoryButtonDownTime = new Date();
    }

    finishMemoryClick(position) {
        const now = new Date();
        const secondsElapsed =  (now.getTime() - this.memoryButtonDownTime.getTime()) / 1000;
        if (secondsElapsed >= 1) {
            // long press
            console.log('long press -- save to memory');
            this.confirmMemorySetAlert(position);
        } else {
            // short press
            if (this.user) {
                if (position === 1) {
                    if (this.user.preference.memoryOne > 0) {
                        this.moveToMemoryPosition(position);
                    } else {
                        this.confirmMemorySetAlert(position);
                    }
                } else { // position === 2
                    if (this.user.preference.memoryTwo > 0) {
                        this.moveToMemoryPosition(position);
                    } else {
                        this.confirmMemorySetAlert(position);
                    }
                }
            } else {
                this.showNeedsLoginAlert('Memory Settings', 'Store your preferred desk heights for ' +
                    'one-touch operation. This feature requires a user account. Would you like to log in or sign up now?');
            }
        }
    }

    connectPressed() {
        if (this.cookieService.check('deskType')) {
            const deskType = this.cookieService.get('deskType');
            if (deskType === 'USB') {
                this.lifeDeskService.connectLifeDeskUsb();
            } else {
                this.router.navigate(['/pair']);
            }
        }
    }

    confirmMemorySetAlert(position: number) {
        let positionVerb: string;
        let property: string;
        switch (position) {
            case 1:
            {
                positionVerb = 'sitting';
                property = 'memoryOne';
            }
                break;
            case 2:
            {
                positionVerb = 'standing';
                property = 'memoryTwo';
            }
                break;
        }
        this.confirmMemorySetModalBody = 'Do you want to set your saved ' + positionVerb + ' position to ' + this.heightLabel + '?';
        this.ngZone.run(() => {
            this.modalService.open(this.confirmMemorySetModal).result.then((result) => {
                this.user.preference[property] = this.lifeDeskService.heightInInches;
                this.sdUserService.saveUser(this.user);
            }).catch((error) => {
                // do not save
            });
        });
    }

    showAlert(title: string, message: string) {
        this.alertTitle = title;
        this.alertBody = message;
        this.ngZone.run(() => {
            this.modalService.open(this.alertModal);
        });
    }

    showNeedsLoginAlert(title: string, message: string) {
        this.alertTitle = title;
        this.alertBody = message;
        this.ngZone.run(() => {
            this.modalService.open(this.needsLoginModal).result.then((result) => {
                // this.router.navigate(['/login']);
                this.oAuthService.initCodeFlow();
            }).catch((error) => {
                // do not save
            });
        });
    }

    showNoTransitionPlanAlert() {
        this.alertTitle = 'Transition Plan';
        this.alertBody = 'Set goals and get reminders to get the most out of your desk. Would you like to choose a transition plan now?';
        this.ngZone.run(() => {
            this.modalService.open(this.needsLoginModal).result.then((result) => {
                this.router.navigate(['/transitions']);
            }).catch((error) => {
                // do not save
            });
        });
    }

    /* onboarding */

    closePopup(popup) {
        this.isUiBlocked = false;
        popup.close();
    }

    memoryNext() {
        this.memorySettingsPopup.close();
        this.transitionPlanPopup.open();
    }

    gotItTransition() {
        this.isUiBlocked = false;
        this.transitionPlanPopup.close();
        if (!this.isUniversalMode) { this.finishOnboarding(); }
    }

    gotItPosture() {
        this.isUiBlocked = false;
        this.posturePopup.close();
        this.finishOnboarding();
    }

    createAccount() {
        this.isUiBlocked = false;
        this.transitionPlanPopup.close();
        if (!this.isUniversalMode) { this.finishOnboarding(); }
        // this.router.navigate(['/login']);
        this.oAuthService.initCodeFlow();
    }

    finishOnboarding() {
        this.isOnboardingStarted = false;
        const now = new Date();
        const future = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
        this.cookieService.set('isOnboardingComplete', 'true', future );
    }

    /* components */

    loadComponent(componentIndex) {
        const componentsNeedingLogin = [0, 3, 4, 5];
        if (!this.user && componentsNeedingLogin.includes(componentIndex) ) {
            this.showNeedsLoginAlert('Login Required', 'This feature requires a user account. ' +
                'Would you like to log in or sign up now?');
        } else {
            this.selectedComponent = componentIndex;
            if (this.screenSize < 3) {
                this.shouldShowHome = false;
                this.columnThreeContainer.clear();
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicComponents[componentIndex]);
                const componentRef = this.columnThreeContainer.createComponent(componentFactory);
                componentRef.changeDetectorRef.detectChanges();
            } else {
                this.colmnTwoContainer.clear();
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicComponents[componentIndex]);
                const componentRef = this.colmnTwoContainer.createComponent(componentFactory);
                componentRef.changeDetectorRef.detectChanges();
            }
        }
    }

    homeClicked() {
        this.selectedComponent = -1;
        this.colmnTwoContainer.clear();
        this.columnThreeContainer.clear();
        this.shouldShowHome = true;
    }

}
