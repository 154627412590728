import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LifeDeskService, LifeDeskStatus} from '../life-desk.service';
import {SDUserService} from '../user.service';
import {RemoteService} from '../remote.service';
import {User} from '../models/user';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

enum ButtonStatus {
    NOT_PRESSED,
    UP_PRESSED,
    DOWN_PRESSED
}

@Component({
  selector: 'app-remote',
  templateUrl: './remote.component.html',
  styleUrls: ['./remote.component.scss']
})
export class RemoteComponent implements OnInit, OnDestroy {

  // private height: number;
  protected heightLabel: string;

  private minHeight;
  private maxHeight;
  private buttonStatus: ButtonStatus;
  private buttonTimer;
  private memoryButtonDownTime: Date;
  user: User;
  private subscription: Subscription;

  constructor(protected lifeDeskService: LifeDeskService, protected sdUserService: SDUserService,
              private remoteService: RemoteService, private modalService: NgbModal) { }

  @ViewChild('memoryNotSetModal', { static: true }) private memoryNotSetModal;
  protected memoryNotSetModalBody: string;
  @ViewChild('confirmMemorySetModal', { static: true }) private confirmMemorySetModal;
  protected confirmMemorySetModalBody: string;

  ngOnInit() {
    this.subscription = new Subscription();
    const heightSubscription = this.lifeDeskService.heightObservable().subscribe((heightInInches) => {
        if (this.lifeDeskService.status === LifeDeskStatus.NOT_MOVING) {
            if (this.buttonStatus === ButtonStatus.NOT_PRESSED) {
                this.remoteService.targetHeight = heightInInches;
                this.heightLabel = heightInInches.toFixed(1) + '"';
            }
        }
        /*
        switch (this.lifeDeskService.status) {
            case LifeDeskStatus.NOT_MOVING:
                if (this.buttonStatus === ButtonStatus.NOT_PRESSED) {
                    this.targetHeight = heightInInches;
                    this.heightLabel = heightInInches.toFixed(1) + '"';
                }
                break;
            case LifeDeskStatus.MOVING_TO_TARGET:
                if (Math.round(this.targetHeight * 10) !== Math.round(heightInInches * 10)) {
                    // We're not there yet, keep going
                    console.log('didUpdateHeight MOVING_TO_TARGET - ' + this.targetHeight.toFixed(1));
                    this.moveToTargetHeight(this.targetHeight, false);
                } else {
                    // Target height reached, so stop trying
                    console.log('didUpdateHeight MOVING_TO_TARGET - Target Reached');
                    this.lifeDeskService.status = LifeDeskStatus.NOT_MOVING;
                }
                break;
            default:
                break;
        }
        */

        });
    this.subscription.add(heightSubscription);

    this.remoteService.shouldUpdateHeightDisplayObservable.subscribe( (targetHeight) => {
        this.heightLabel = targetHeight.toFixed(1) + '"';
    });

    if (!isNaN(this.lifeDeskService.heightInInches)) {
        this.remoteService.targetHeight = this.lifeDeskService.heightInInches;
        this.heightLabel = this.lifeDeskService.heightInInches.toFixed(1) + '"';
    }
    this.minHeight = 21.0;
    this.maxHeight = 51.0;
    this.buttonStatus = ButtonStatus.NOT_PRESSED;
    this.sdUserService.getUser().subscribe((user) => {
        this.user = user;
    });
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

  upPressed() {
      this.lifeDeskService.status = LifeDeskStatus.NOT_MOVING;
      this.stopAction();
      if (this.remoteService.targetHeight < this.maxHeight) {
          this.remoteService.targetHeight += 0.1;
          this.heightLabel = this.remoteService.targetHeight.toFixed(1) + '"';
          this.buttonStatus = ButtonStatus.UP_PRESSED;
          this.startButtonTimer();
      }
  }

  downPressed() {
    this.lifeDeskService.status = LifeDeskStatus.NOT_MOVING;
    this.stopAction();
    if (this.remoteService.targetHeight > this.minHeight) {
      this.remoteService.targetHeight -= 0.1;
        this.heightLabel = this.remoteService.targetHeight.toFixed(1) + '"';
        this.buttonStatus = ButtonStatus.DOWN_PRESSED;
        this.startButtonTimer();
    }
  }

  directionReleased() {
      this.buttonStatus = ButtonStatus.NOT_PRESSED;
      this.lifeDeskService.status = LifeDeskStatus.MOVING_TO_TARGET;
      this.remoteService.moveToTargetHeight(this.remoteService.targetHeight, true);
      this.lifeDeskService.resetIdleTimer();
  }

  stopAction() {
      this.lifeDeskService.stopAction();
  }

  startButtonTimer() {
      this.buttonTimer = setInterval(function() {this.continueButtonPress(); }.bind(this), 200);
  }

  continueButtonPress() {
    switch (this.buttonStatus) {
      case ButtonStatus.NOT_PRESSED:
          console.log('not pressed');
        clearInterval(this.buttonTimer);
        break;
      case ButtonStatus.UP_PRESSED:
          if (this.remoteService.targetHeight < this.maxHeight) {
              this.remoteService.targetHeight += 0.1;
              this.heightLabel = this.remoteService.targetHeight.toFixed(1) + '"';
          }
        break;
      case ButtonStatus.DOWN_PRESSED:
          if (this.remoteService.targetHeight > this.minHeight) {
              this.remoteService.targetHeight -= 0.1;
              this.heightLabel = this.remoteService.targetHeight.toFixed(1) + '"';
          }
        break;
      default:
        break;
    }
  }

  startMemoryClick(position) {
      this.memoryButtonDownTime = new Date();
  }

  finishMemoryClick(position) {
      const now = new Date();
      const secondsElapsed =  (now.getTime() - this.memoryButtonDownTime.getTime()) / 1000;
      // console.log(secondsElapsed);
      if (secondsElapsed >= 1) {
          // long press
          console.log('long press -- save to memory');
          this.confirmMemorySetAlert(position);
      } else {
          // short press
          if (this.remoteService.moveToMemoryPosition(position)) {
              this.heightLabel = this.remoteService.targetHeight.toFixed(1) + '"';
          } else {
              this.showMemoryNotSetAlert(position);
          }
      }
  }

  showMemoryNotSetAlert(position: number) {
    let positionVerb: string;
    let positionName: string;
    switch (position) {
        case 1:
        {
            positionVerb = 'sitting';
            positionName = 'Sit';
        }
            break;
        case 2:
        {
            positionVerb = 'standing';
            positionName = 'Stand';
        }
            break;
    }
    this.memoryNotSetModalBody = 'You have not saved a value for ' + positionVerb +
        ' position. To set the memory, adjust your LifeDesk to the desired height, then press and hold the ' + positionName + ' button.';
    this.modalService.open(this.memoryNotSetModal);

  }

    confirmMemorySetAlert(position: number) {
        let positionVerb: string;
        let property: string;
        switch (position) {
            case 1:
            {
                positionVerb = 'sitting';
                property = 'memoryOne';
            }
                break;
            case 2:
            {
                positionVerb = 'standing';
                property = 'memoryTwo';
            }
                break;
        }
        this.confirmMemorySetModalBody = 'Do you want to set your saved ' + positionVerb + ' position to ' + this.heightLabel + '?';
        this.modalService.open(this.confirmMemorySetModal).result.then((result) => {
            // make sure user is up to date before saving
            this.sdUserService.getUser().subscribe((user) => {
                this.user = user;
                this.user.preference[property] = this.remoteService.targetHeight;
                this.sdUserService.saveUser(this.user);
            });
        }).catch((error) => {
            // do not save
        });
    }
}
