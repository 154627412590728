import {Component, NgZone, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { LifeDeskService } from '../life-desk.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import {RolesService} from '../roles.service';

@Component({
  selector: 'app-pair',
  templateUrl: './pair.component.html',
  styleUrls: ['./pair.component.scss']
})
export class PairComponent implements OnInit, OnDestroy {

  protected height;
  @ViewChild('checkSwitchModal', { static: true }) private checkSwitchModal;
  @ViewChild('succesfullyPairedModal', { static: true }) private succesfullyPairedModal;
  @ViewChild('tryAgainModal', { static: true }) private tryAgainModal;
  private hasOpenModel = false;
  private subscription: Subscription;
  private isUniversalMode = false;

  constructor(protected lifeDeskService: LifeDeskService, private modalService: NgbModal,
              private router: Router, private zone: NgZone, private cookieService: CookieService,
              private rolesService: RolesService) { }

    ngOnInit() {
        if (this.cookieService.check('deskType')) {
            const deskType = this.cookieService.get('deskType');
            if (deskType === 'Universal') {
                this.isUniversalMode = true;
            }
        }
        this.subscription = new Subscription();
        const heightSubscription = this.lifeDeskService.heightObservable().subscribe((heightInInches) => {
          this.height = heightInInches;
            if (this.lifeDeskService.isPairing) {
                if (!this.hasOpenModel) {
                    this.hasOpenModel = true;
                    this.modalService.open(this.checkSwitchModal).result.then((result) => {
                        this.hasOpenModel = false;
                        console.log('found our LifeDesk');
                        this.lifeDeskService.isPairing = false;
                        const now = new Date();
                        const future = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());
                        // const future = now.getTime() + (10 * 365.25 * 24 * 60 * 60 * 1000); // ten years from now
                        console.log('writing cookie value: ' + this.lifeDeskService.lifeDeskPeripheralName);
                        this.cookieService.set('LifeDeskName', this.lifeDeskService.lifeDeskPeripheralName, future );
                        this.rolesService.getBrand(this.lifeDeskService.lifeDeskPeripheralName)
                            .subscribe((object: any) => {
                                if (object.brand) {
                                    this.cookieService.set('Brand', object.brand, future );
                                }
                                this.showSuccessModal();
                            });
                    }).catch((error) => {
                        this.hasOpenModel = false;
                        console.log('not our LifeDesk');
                        this.lifeDeskService.disconnectLifeDesk();
                    });
                }
            }
        });
        this.subscription.add(heightSubscription);
        const connectedSubscription = this.lifeDeskService.connectedObservable().subscribe((connected) => {
            if (!connected) {
                console.log('disconnected');
                if (this.lifeDeskService.isPairing) {
                    this.lifeDeskService.isPairing = false;
                    this.modalService.open(this.tryAgainModal).result.then( (result) => {
                        this.connect();
                    });
                }
            }
        });
        this.subscription.add(connectedSubscription);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    connect() {
        this.lifeDeskService.isPairing = true;
        this.lifeDeskService.connectLifeDesk('');
        // this.lifeDeskService.connectLifeDesk('BLE Device-B93851');
    }

    forget() {
        this.cookieService.delete('LifeDeskName');
        this.lifeDeskService.disconnectLifeDesk();
    }

    showSuccessModal() {
        if (!this.hasOpenModel) {
            this.hasOpenModel = true;
            this.modalService.open(this.succesfullyPairedModal).result.then( (result) => {
                this.hasOpenModel = false;
                this.lifeDeskService.setDeskType('LifeDesk');
                this.router.navigate(['/home']);
            }).catch ( (error) => {
                this.hasOpenModel = false;
            });
        }
    }
}
