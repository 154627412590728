const DidYouKnows = [
    {
        id: 1,
        title: 'Did you know?',
        text: '<p>There is significant correlation between excessive sitting time and the development of Type 2 ' +
            'diabetes. Breaking a sedentary spell with standing or other light-intensity activity can impact insulin ' +
            'levels and thus improve blood glucose metabolism. Standing for at least 15 minutes after a meal can ' +
            'reduce blood glucose levels by 25% to 50% according to published medical literature.</p>'
    },
    {
        id: 2,
        title: 'Did you know?',
        text: '<p>Research suggests that regular movement boosts natural anti-oxidants that kill cell-damaging (and ' +
            'potentially cancer causing) free-radicals. Studies correlate excessive sitting with a higher risk for ' +
            'developing both breast and colon cancer, so frequent posture change could be a preventative strategy.</p>'
    },
    {
        id: 3,
        title: 'Did you know?',
        text: '<p>Standing delivers more oxygen and more nutrients to the brain. This may be due to 1) more efficient ' +
            'use of the diaphragm muscle for deeper breathing, allowing for more highly oxygenated blood, and 2) ' +
            'contracting skeletal muscle helps deliver greater blood flow to the brain.</p>'
    },
    {
        id: 4,
        title: 'Did you know?',
        text: '<p>Compressive forces on the spine are significantly less in standing then in sitting. Studies show that ' +
            'when you sit in a traditional upright posture (90-90-90) compressive forces in the spine are 40% higher ' +
            'than in standing. When you sit forward, slightly bent at the waist, knees flexed (the typical computer ' +
            'interaction posture) compressive forces on the spine are about 70% higher than in standing. This is ' +
            'significant if you have degenerative disc disease or low back nerve root irritation and why standing ' +
            'often provides pain relief.</p>'
    },
    {
        id: 5,
        title: 'Did you know?',
        text: '<p>When one is seated for a long period of time, the hip flexor muscles can become very tight. Over time, ' +
            'chronic tightness can become a significant problem, leading to low back pain. Hip flexor tightness has also ' +
            'been shown to have an impact on mental health due to its “fight or flight” link to the amygdala of the brain. ' +
            'Frequent posture change can prevent both conditions by keeping hip flexors from becoming excessively tight.</p>'
    },
    {
        id: 6,
        title: 'Did you know?',
        text: '<p>When you stand, contraction of slow-twitch postural muscles causes the release of the enzyme Lipoprotein ' +
            'Lipase (LPL). LPL facilitates the breakdown of fats and increases levels of HDL (the good cholesterol). ' +
            'During bouts of excessive sitting, LPL levels drop significantly leading to increased fat storage and greater ' +
            'risk of developing cardiovascular disease.</p>'
    },
    {
        id: 7,
        title: 'Did you know?',
        text: '<p>That when changing posture, it is also a great time to take care of your eyes to prevent computer vision ' +
            'syndrome also known as Dry Eye Syndrome (DES). Remember the 20 -20-20 rule: every 20 minutes, look at an ' +
            'object at least 20 feet away for a period of 20 seconds. This will help reduce the risk of developing vision ' +
            'issues by allowing your eye muscles to briefly relax and facilitate blinking to rehydrate themselves. Protect ' +
            'your eyes and your body by moving at least every 20 minutes.</p>'
    }
];

export {DidYouKnows};
