import { Component, OnInit, NgZone  } from '@angular/core';
import { DataStoreService, DataStoreType, Query } from 'kinvey-angular-sdk';
import { Survey } from '../models/survey';
import { SurveyResponse } from '../models/survey-response';
import { SDUserService } from '../user.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})

export class SurveyComponent implements OnInit {

  private questions: Survey[];
  private responses: SurveyResponse[];
  private questionIndex: number;
  private totalQuestions: number;

  public values: any[];
  public valueLabels: string[];
  public showNumberError = false;
  public nextButtonText: string;

  constructor(private zone: NgZone, private sdUserService: SDUserService, private router: Router,
              private dataStoreService: DataStoreService) { }

  ngOnInit() {
    this.values = [];
    this.valueLabels = [];
    this.responses = [];
    this.nextButtonText = 'Next';
    this.sdUserService.getUser().subscribe((user) => {
      const purchaseDate = new Date(user.preference.purchaseDate);
      const now = new Date();
      const dateDiff = Math.floor((now.getTime() - purchaseDate.getTime()) / (1000 * 60 * 60 * 24));
      let surveyName;
      if (dateDiff > 60) {
        surveyName = 'Existing LifeDesk';
      } else {
        surveyName = 'New LifeDesk';
      }
      const store = this.dataStoreService.collection('Survey', DataStoreType.Network);
      const query = new Query();
      query.equalTo('SurveyName', surveyName);
      store.find(query)
        .subscribe((surveys) => {
          this.zone.run(() => {
              this.questions = surveys;
              this.questionIndex = 0;
              this.totalQuestions = this.questions.length;
              if (this.values.length === 0 ) { // skip if second call
                  for (let i = 0; i < this.questions.length; i++) {
                      let initialValue;
                      if (this.questions[i].Type === 'Number') {
                          initialValue = 0;
                      }
                      if (this.questions[i].Type === 'Select Multiple') {
                          initialValue = [];
                      }
                      if (this.questions[i].Type === 'Multiline Text') {
                          initialValue = '';
                      }
                      if (this.questions[i].Type === 'Slider') {
                          initialValue = 0;
                          if (this.questions[i].Parameters) {
                              if (this.questions[i].Parameters.hasOwnProperty('min')) {
                                  initialValue = this.questions[i].Parameters['min'];
                              }
                          }
                      }
                    this.values.push(initialValue);
                    this.valueLabels.push('');

                    const surveyResponse = new SurveyResponse();
                    surveyResponse.SurveyName = this.questions[i].SurveyName;
                    surveyResponse.QuestionNumber = this.questions[i].QuestionNumber;
                    surveyResponse.QuestionName = this.questions[i].QuestionName;
                    this.responses.push(surveyResponse);
                  }
                  // console.log(this.values);
              }
          });
        });
    });
  }

  onNextQuestion() {
    if (this.questionIndex + 1 < this.totalQuestions) {
      this.saveResponse();
      this.questionIndex ++;
      if (this.questionIndex + 1 === this.totalQuestions) {
          this.nextButtonText = 'Done';
      }
    } else {
        if (this.questionIndex + 1 === this.totalQuestions) {
            this.saveResponse();
            this.sdUserService.getUser().subscribe((user) => {
                user.preference.lastSurvey = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
                this.sdUserService.saveUser(user);
            });
            this.router.navigate(['/profile'], {queryParams: {survey: true}});
        }
    }
  }

  onPreviousQuestion() {
    if (this.questionIndex > 0) {
        this.questionIndex --;
        if (this.questionIndex === this.totalQuestions) {
            this.nextButtonText = 'Next';
        }
    }
  }

  onSliderChange(value: any) {
    this.valueLabels[this.questionIndex] = this.values[this.questionIndex].toString();
  }

  onNumberChange() {
    if (isNaN(Number(this.values[this.questionIndex]))) {
        this.values[this.questionIndex] = 0;
        this.valueLabels[this.questionIndex] = '';
        this.showNumberError = true;
    } else {
        this.values[this.questionIndex] = Number(this.values[this.questionIndex]);
        this.valueLabels[this.questionIndex] = this.values[this.questionIndex].toString();
        this.showNumberError = false;
    }
  }

  onSelectChange() {
      if (this.values[this.questionIndex].count === 0) {
          this.valueLabels[this.questionIndex] = '';
      } else {
          this.valueLabels[this.questionIndex] = this.values[this.questionIndex].toString();
      }
  }

  onTextChange() {
      this.valueLabels[this.questionIndex] = this.values[this.questionIndex];
  }

  saveResponse() {
      const indexToSave = this.questionIndex; // because question index will likely advance before save is complete
      if (Array.isArray(this.values[indexToSave])) {
          this.responses[indexToSave].Values = this.values[indexToSave];
      } else {
          this.responses[indexToSave].Values = [this.values[indexToSave]];
      }
      const store = this.dataStoreService.collection('SurveyResponses');
      store.save(Object.assign( {}, this.responses[indexToSave]))
          .then((entity: SurveyResponse) => {
              this.responses[indexToSave] = entity;
          }).catch((error) => {

          });
  }

}
