import { Component, OnInit, Input } from '@angular/core';
import {NewsArticle} from '../models/news-article';


@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit {
  @Input() newsArticle: NewsArticle;

  constructor() { }

  ngOnInit() {
  }

}
