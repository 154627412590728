import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestServiceOneService {

  public getValue: Observable<any>;
  private value: string;
  public timeStamp: string;

  constructor() {
    this.value = '';
    this.timeStamp = new Date().toISOString();
    this.getValue = new Observable(observer => {
      if (this.value === '') {
        observer.next('fresh' + new Date().toISOString());
        this.value = 'stale';
        observer.complete();
      } else {
        observer.next(this.value + new Date().toISOString());
        observer.complete();
      }
    }).pipe(share());
  }
}
