import { Component, OnInit } from '@angular/core';
import {TestServiceOneService} from '../test-service-one.service';
import {TestServiceTwoService} from "../test-service-two.service";

@Component({
  selector: 'app-test-component-one',
  templateUrl: './test-component-one.component.html',
  styleUrls: ['./test-component-one.component.scss']
})
export class TestComponentOneComponent implements OnInit {

  protected valueOne: string;
  protected timeStampOne: string;

    protected valueTwo: string;
    protected timeStampTwo: string;

    protected valueServiceTwo: string;
    protected timeStampServiceTwo: string;

  constructor(private testServiceOneServiceFirst: TestServiceOneService, private testServiceOneServiceSecond: TestServiceOneService,
              private testServiceTwoService: TestServiceTwoService) {

  }

  ngOnInit() {
  }

  subscribeToTestServiceOneFirst() {
      this.testServiceOneServiceFirst.getValue.subscribe(value => {
        this.valueOne = value;
        this.timeStampOne = this.testServiceOneServiceFirst.timeStamp;
      });
  }

    subscribeToTestServiceOneSecond() {
        this.testServiceOneServiceSecond.getValue.subscribe(value => {
            this.valueTwo = value;
            this.timeStampTwo = this.testServiceOneServiceSecond.timeStamp;
        });
    }

    getValueFromServiceTwo() {
        this.valueServiceTwo = this.testServiceTwoService.value;
        this.timeStampServiceTwo = this.testServiceTwoService.timeStamp;
    }

}
