import {Component, NgZone, OnDestroy, OnInit, ViewChild, AfterViewInit, HostListener, ElementRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import {LifeDeskService, LifeDeskStatus} from '../life-desk.service';
import { SDUserService } from '../user.service';
import {User} from '../models/user';
import {Subscription} from 'rxjs';
import { environment} from '../../environments/environment';
import {ActivityService} from '../activity.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as moment from 'moment';
import {Moment} from 'moment';
import { UserService } from 'kinvey-angular-sdk';
import { ScreenSizeService} from '../screen-size.service';
import {OAuthEvent, OAuthService} from 'angular-oauth2-oidc';
import {ApiService} from '../api-service';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    title = 'LifeDesk';
    @ViewChild('reminderModal', { static: true }) private reminderModal;
    @ViewChild('connectModal', { static: true }) private connectModal;
    @ViewChild('postureConfirmationModal', { static: true }) private postureConfirmationModal;
    @ViewChild('surveyModal', { static: true }) private surveyModal;
    @ViewChild('welcomeBackModal', {static: true }) private welcomeBackModal;
    @ViewChild('returnModal', {static: true }) private returnModal;
    protected savedLifeDeskName: string;
    protected reminderTitle: string;
    protected reminderBody: string;
    // private wakeTimer;
    // private wakeCheckIn;
    // private wakeCheckInInterval = 5 * 1000;
    private isUniversalMode;
    private user: User;
    private subscription: Subscription;
    protected postureConfirmationMessage: string;
    protected isWaiting: boolean;

    protected surveyMessage: string;
    protected notNowText: string;
    protected showNotNow: boolean;
    protected allowNavigation = true;
    protected brand: string;
    protected brandAlt: string;

    private lastVisible;
    private currentPosture: string;
    private shouldSetReturnPosture = false;

    private unrespondedNotifications = 0;

    private loginLogout = 'Log In';

    /* size detection */
    prefix = 'is-';
    sizes = [
        {
            id: 0, name: 'xs', css: `d-block d-sm-none`
        },
        {
            id: 1, name: 'sm', css: `d-none d-sm-block d-md-none`
        },
        {
            id: 2, name: 'md', css: `d-none d-md-block d-lg-none`
        },
        {
            id: 3, name: 'lg', css: `d-none d-lg-block d-xl-none`
        },
        {
            id: 4, name: 'xl', css: `d-none d-xl-block`
        },
    ];
    private currentSize: number;

    constructor(private modalService: NgbModal, private cookieService: CookieService,
                private lifeDeskService: LifeDeskService, private sdUserService: SDUserService, private ngZone: NgZone,
                private activityService: ActivityService, private router: Router, private userService: UserService,
                private elementRef: ElementRef, private screenSizeService: ScreenSizeService,
                private oAuthService: OAuthService, private apiService: ApiService, private route: ActivatedRoute) {}

    ngOnInit() {
        /*
        this.wakeCheckIn = new Date().getTime();
        this.wakeTimer = setInterval(() => {
            const now = new Date();
            console.log((now.getTime() - this.wakeCheckIn));
            if ((now.getTime() - this.wakeCheckIn) > (this.wakeCheckInInterval * 2)) { // did not check in for two minutes
                console.log('you were away');
                // this.showAlert('Welcome Back', 'Do you want to transition now?');
            }
            this.wakeCheckIn = now;
        }, this.wakeCheckInInterval);
        */

        if (this.route.snapshot.queryParams.dt) {
            this.lifeDeskService.setDeskType(this.route.snapshot.queryParams.dt);
        }
        if (this.route.snapshot.queryParams.shouldLogin) {
            this.oAuthService.initCodeFlow();
        }

        this.checkBrand();

        this.subscription = new Subscription();
        if (this.oAuthService.hasValidAccessToken()) {
            // console.log('has valid access token');
            this.loginLogout = 'Log Out';
        } else {
            // console.log('does not have valid access token');
            this.loginLogout = 'Log In';
        }

        const oAuthSubscription = this.oAuthService.events.subscribe((oAuthEvent: OAuthEvent) => {
            if (oAuthEvent.type === 'token_received' ||
                oAuthEvent.type === 'token_refreshed' ||
                oAuthEvent.type === 'logout') {
                this.loginLogout = (this.oAuthService.hasValidAccessToken()) ? 'Log Out' : 'Log In';
                this.sdUserService.refreshUser();
            }
        });

        const userSubscription = this.sdUserService.getUser().subscribe(
            user => {
                this.user = user;
                // this.loginLogout = (user) ? 'Log Out' : 'Log In';
                if (this.user && this.user.needsToTakeSurvey) {
                    this.ngZone.run(() => {
                        this.checkSurvey();
                    });
                }
            });
        this.subscription.add(userSubscription);

        const connectedSubscription = this.lifeDeskService.connectedObservable().subscribe((connected) => {
            if (connected) {
                console.log('app component sees reconnect');
                this.showAlert('Reconnected', 'Would you like to transition now?' );
            } else {
                this.checkForSavedLifeDesk();
            }
        });
        this.subscription.add(connectedSubscription);

        const alertSubscription = this.lifeDeskService.alertObservable.subscribe( (value) => {
            this.showAlert(value.title, value.body);
        });
        this.subscription.add(alertSubscription);

        const deskTypeSubscription = this.lifeDeskService.deskTypeObservable().subscribe( (deskType) => {
            this.updateDeskType();
        });
        this.subscription.add(deskTypeSubscription);

        const activitySubscription = this.activityService.isWaitingObservable().subscribe ( isWaiting => {
            this.ngZone.run(() => {
                this.isWaiting = isWaiting;
            });
        });
        this.subscription.add(activitySubscription);

        const postureSubscription = this.lifeDeskService.postureObservable().subscribe( posture => {
            if (posture === 'away' && this.shouldSetReturnPosture) {
                this.shouldSetReturnPosture = false;
                if (this.isUniversalMode) {
                    if (!this.modalService.hasOpenModals()) {
                        this.shouldSetReturnPosture = false;
                        this.ngZone.run(() => {
                            this.modalService.open(this.returnModal).result.then((result) => {
                                this.activityService.isHomeBusy = true;
                                this.lifeDeskService.logEvent(result, 'Universal');
                            }).catch((error) => {
                                // do nothing
                            });
                        });
                    }
                } else {
                    this.activityService.isHomeBusy = true;
                    this.lifeDeskService.logEvent(this.lifeDeskService.position, this.lifeDeskService.lifeDeskPeripheralName);
                }
            }
            if (posture === 'sit' || posture === 'stand') {
                this.setLastVisible(); // we "saw" user becuase he or she transitioned
            }
        });
        this.subscription.add(postureSubscription);

        // TODO not sure if this is the best way to do this
        this.router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                if (e['url'] === '/home') {
                    // console.log('routed home');
                    if (!this.allowNavigation) {
                        this.allowNavigation = true;
                    }
                    if (!this.brand) {
                        this.checkBrand();
                    }
                }
            }
        });

        /*
        setTimeout(() => {
            this.savedLifeDeskName = this.cookieService.get('LifeDeskName');
            if (this.savedLifeDeskName !== '') {
                this.showConnectModal();
            }
        });
        */

        this.updateDeskType();

        // assumes computer allows sleep
        document.addEventListener('visibilitychange', (event) => {
            console.log('on visibility change');
            if (document.hidden) {
                this.setLastVisible();
                console.log('StanData App is Hidden');
            } else {
                this.checkLastVisible();
                this.setLastVisible(); // set it after check, because we are now visible
            }
        } , false);

        // if computer never sleeps, last visible is not updated. Let's reset at focus and blur
        window.addEventListener('focus', (event) => {
            console.log('on focus');
            // TODO should we check last visible here?
            this.setLastVisible(); // set it after check, because we are now visible
        } , false);
        window.addEventListener('blur', (event) => {
            console.log('on blur');
            this.setLastVisible(); // set it after check, because we are now visible
        }, false);


        console.log('on init');
        this.checkLastVisible();
        this.setLastVisible(); // set it after check, because we are now visible
    }

    checkBrand() {
        if (this.cookieService.check('deskType')) {
            const deskType = this.cookieService.get('deskType');
            if (deskType === 'Universal') {
                this.isUniversalMode = true;
                this.brand = '/assets/images/standata.svg';
                this.brandAlt = 'StanData Logo';
            } else {
                this.isUniversalMode = false;
                if (this.cookieService.check('Brand')) {
                    this.brand = environment.BRAND_URL + this.cookieService.get('Brand');
                    this.brandAlt = '';
                } else {
                    this.brand = '/assets/images/lifedesk.svg';
                    this.brandAlt = 'LifeDesk Logo';
                }
            }
        }
    }

    @HostListener('window:resize', [])
    private onResize() {
        this.detectScreenSize();
    }

    ngAfterViewInit() {
        this.detectScreenSize();
    }

    private detectScreenSize() {
        const newSize = this.sizes.find(x => {
            // get the HTML element
            const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
            // check its display property value
            return window.getComputedStyle(el).display !== 'none';
        })['id'];
        if (newSize !== this.currentSize) {
            this.currentSize = newSize;
            this.screenSizeService.setScreenSize(this.currentSize);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    checkLastVisible() {
        this.lastVisible = moment(this.cookieService.get('lastVisible'));
        console.log('StanData App is Visible. Last Visible: ' + this.lastVisible.toISOString());
        if (this.oAuthService.hasValidAccessToken()) {
            if (this.user.lastEvent.value !== 'Away') {
                const minutesAgo = moment().diff(this.lastVisible, 'minutes');
                console.log('last seen ' + minutesAgo + ' minutes ago.');
                if (minutesAgo >= environment.LAST_SEEN_CUTOFF) {
                    if (moment().isSame(this.lastVisible, 'day')) {
                        // Last visible was today, show Welcome Back message
                        if (this.modalService.hasOpenModals()) {
                            this.modalService.dismissAll();
                        }
                        this.showWereYouAwayModal();
                    } else {
                        // Last visible was on a different day, just log away and move on
                        let key: string;
                        if (this.isUniversalMode) {
                            key = 'Universal';
                        } else {
                            key = this.lifeDeskService.lifeDeskPeripheralName;
                        }
                        this.shouldSetReturnPosture = true; // prompt for new posture after event is saved
                        this.activityService.isHomeBusy = true;
                        this.lifeDeskService.logEvent('away', key, this.lastVisible.toDate());
                    }
                }
            }
        }
    }

    setLastVisible() {
        this.cookieService.set('lastVisible', moment().toISOString(), moment().add(10, 'years').toDate());
    }

    updateDeskType() {
        if (this.cookieService.check('deskType')) {
            const deskType = this.cookieService.get('deskType');
            if (deskType === 'Universal') {
                this.isUniversalMode = true;
            } else {
                this.isUniversalMode = false;
            }
        }
    }

    checkForSavedLifeDesk() {
        console.log('checking for saved LifeDesk');
        setTimeout(() => {
            if (this.cookieService.check('LifeDeskName')) {
                console.log('LifeDeskName cookie exists');
                this.savedLifeDeskName = this.cookieService.get('LifeDeskName');
                if (this.savedLifeDeskName !== '') {
                    this.showConnectModal();
                    this.showNotification('Welcome Back', 'Would you like to connect to your LifeDesk?');
                } else {
                    console.log('LifeDeskName cookie not found');
                }
            } else {
                console.log('LifeDeskName cookie does not exist');
            }
        });
    }

    logInOrOut() {
        /*if (this.user) {
            // log out
            this.userService.logout()
                .then(() => {
                    this.cookieService.delete('standata.user');
                    this.sdUserService.refreshUser();
                    this.ngZone.run(() => {
                        // this.router.navigate(['/login']);
                    });
                });
        } else {
            // log in
            this.router.navigate(['/login']);
        }*/
        if (this.oAuthService.hasValidAccessToken()) {
            // log out
            // kind of hacky, but this makes it work
            // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/504
            sessionStorage.setItem('id_token', 'nothing');
            this.oAuthService.logOut(false);
        } else {
            // log in
            // console.log('initiating code flow');
            this.oAuthService.initCodeFlow();
        }
    }

    showAlert(title, body) {
        if (!this.modalService.hasOpenModals() && this.oAuthService.hasValidAccessToken()) {
            this.reminderTitle = title;
            this.reminderBody = body;
            this.ngZone.run(() => {
                this.modalService.open(this.reminderModal).result.then((result) => {
                    if (this.isUniversalMode) {
                        this.showPostureChangeConfirmationAlert();
                    } else {
                        this.moveToMemoryPosition((this.lifeDeskService.heightInInches >= environment.STAND_THRESHOLD) ? 1 : 2);
                    }
                }).catch((error) => {
                    // do nothing
                });
            });
        }
        this.showNotification(title, body);
    }

    showNotification(title, body) {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
            alert('This browser does not support system notifications');
            // Let's check whether notification permissions have already been granted
        } else if ((Notification as any).permission === 'granted') {
            // If it's okay let's create a notification
            this.createNotification(title, body);
        } else if ((Notification as any).permission !== 'denied') { // Otherwise, we need to ask the user for permission
            Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === 'granted') {
                    this.createNotification(title, body);
                }
            });
        }
    }

    createNotification(title, body) {
        if (this.unrespondedNotifications >= 2) {
            console.log('two or more unresponded notifications -- starts on third');
            this.checkLastVisible();
        }
        this.unrespondedNotifications ++;
        const img = '/assets/images/sdicon.png';
        const notification = new Notification(title,
            { body: body, icon: img, requireInteraction: true });
        notification.onclick = function(event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            this.setLastVisible(); // we "see" the user on click
            window.focus();
            this.close();
        }.bind(this);
        notification.onclose = function(event) {
            this.unrespondedNotifications = 0;
            this.setLastVisible(); // we "see" the user on close
        }.bind(this);
    }

    showWereYouAwayModal() {
        if (this.user.lastEvent.value === 'sit') {
            this.currentPosture = 'sitting';
        } else if (this.user.lastEvent.value === 'stand') {
            this.currentPosture = 'standing';
        }
        this.ngZone.run(() => {
            this.modalService.open(this.welcomeBackModal).result.then((result) => {
                if (result === 'yes') {
                    // do nothing
                }
                if (result === 'no') {
                    // create new event for away
                    let key: string;
                    if (this.isUniversalMode) {
                        key = 'Universal';
                    } else {
                        key = this.lifeDeskService.lifeDeskPeripheralName;
                    }
                    this.shouldSetReturnPosture = true; // prompt for new posture after event is saved
                    this.activityService.isHomeBusy = true;
                    this.lifeDeskService.logEvent('away', key, this.lastVisible.toDate());
                }
            }).catch((error) => {
                // do nothing
            });
        });
    }

    showConnectModal() {
        if (!this.modalService.hasOpenModals()) {
            this.modalService.open(this.connectModal).result.then((result) => {
                this.lifeDeskService.connectLifeDesk(this.savedLifeDeskName);
            }).catch((error) => {
            });
        }
    }

    moveToMemoryPosition(position: number) {
        let target = 0;
        switch (position) {
            case 1:
            {
                target = this.user.preference.memoryOne;
            }
                break;
            case 2:
            {
                target = this.user.preference.memoryTwo;
            }
                break;
            default:
                break;
        }
        if (target > 0) {
            // home page set height display
            const subscription: Subscription = this.lifeDeskService.moveToTargetHeightObservable(target)
                .subscribe(
                    height => {
                        // nothing?
                    },
                    error => {
                        console.log(error);
                        subscription.unsubscribe();
                    },
                    () => {
                        console.log('complete');
                        subscription.unsubscribe();
                    }
                );
        }
    }

    showPostureChangeConfirmationAlert() {
        let message: string;
        let event: string;
        if (this.user.lastEvent.value === 'stand') {
            message = 'Please confirm that you have changed your posture to sitting.';
            event = 'sit';
        } else if (this.user.lastEvent.value === 'sit') {
            message = 'Please confirm that you have changed your posture to standing.';
            event = 'stand';
        } else {
            // this should never happen
        }
        this.postureConfirmationMessage = message;
        this.modalService.open(this.postureConfirmationModal).result.then((result) => {
            // make sure user is up to date before saving
            if (result === 'confirmed') {
                this.activityService.isHomeBusy = true;
                this.lifeDeskService.logEvent(event, 'Universal');
            }
            if (result === 'away') {
                this.activityService.isHomeBusy = true;
                this.lifeDeskService.logEvent('away', 'Universal');
            }
        }).catch((error) => {
            // do nothing
        });
    }

    checkSurvey() {
        // testing only
        // this.cookieService.delete('mustCompleteSurveyByDate');
        // this.cookieService.delete('lastSurveyReminderDate');

        this.showNotNow = true;
        let message: string;
        this.notNowText = 'Not Now';
        let isNewUser: boolean;
        if (!this.user.preference.lastSurvey || this.user.preference.lastSurvey.length === 0) {
            isNewUser = true;
        } else {
            isNewUser = false;
        }
        if (this.user.isSurveyRequired) {
            if (isNewUser) {
                // this is a new user survey, force them to take it now
                this.showNotNow = false;
                message = 'Please take two minutes to complete your profile information and take a brief survey. ' +
                    'This will help us improve our app and provide you with better feedback on your health and wellness. ' +
                    'Your personal data will remain anonymous and confidential. Your employer requires you to complete ' +
                    'this before continuing to use the app.';
            } else {
                // this is a pushed survey, give them some time to take it (2 days)
                let mustCompleteSurveyByDate: Moment;
                const now = moment();
                if (this.cookieService.check('mustCompleteSurveyByDate')) {
                    const dateString = this.cookieService.get('mustCompleteSurveyByDate');
                    mustCompleteSurveyByDate = moment(dateString);
                } else {
                    mustCompleteSurveyByDate = now.clone().add(2, 'days');
                    const future = now.clone().add(10, 'years');
                    this.cookieService.set('mustCompleteSurveyByDate', mustCompleteSurveyByDate.toISOString(), future.toDate() );
                }
                if (now.isAfter(mustCompleteSurveyByDate)) {
                    this.showNotNow = false;
                    message = 'Please take two minutes to take a brief survey. This will help us improve our app and ' +
                        'provide you with better feedback on your health and wellness. Your personal data will remain ' +
                        'anonymous and confidential. Your employer requires you to complete this before continuing to ' +
                        'use the app.';
                } else {
                    const days = mustCompleteSurveyByDate.diff(now, 'days'); // [self daysBetween:now and:mustCompleteSurveyByDate];
                    console.log('days between: ' + days);
                    message = 'Please take two minutes to take a brief survey. This will help us improve our app and ' +
                        'provide you with better feedback on your health and wellness. Your personal data will remain ' +
                        'anonymous and confidential. You have ' + days.toFixed(0) + ' day(s) to complete ' +
                        'this to avoid interruption of the app.';
                }
            }
        } else {
            // not required
            let lastSurveyReminderDate = moment('1970-01-01');
            if (this.cookieService.check('lastSurveyReminderDate')) {
                const dateString = this.cookieService.get('lastSurveyReminderDate');
                lastSurveyReminderDate = moment(dateString);
            }
            const now = moment();
            if (lastSurveyReminderDate.isSame(now.startOf('day'))) {
                // we already saw a reminder today, don't show again
                return;
            }
            this.notNowText = 'Remind Me Tomorrow';
            if (isNewUser) {
                // this is a new user survey
                message = 'Please take two minutes to complete your profile information and take a brief survey. ' +
                    'This will help us improve our app and provide you with better feedback on your health and ' +
                    'wellness. Your personal data will remain anonymous and confidential.';
            } else {
                // this is a pushed survey
                message = 'Please take two minutes to take a brief survey. This will help us improve our app and ' +
                    'provide you with better feedback on your health and wellness. Your personal data will remain ' +
                    'anonymous and confidential.';
            }
        }
        this.surveyMessage = message;
        this.modalService.open(this.surveyModal, {backdrop: 'static'}).result.then((result) => {
            // make sure user is up to date before saving
            if (result === 'notNow') {
                if (!this.user.isSurveyRequired) {
                    const now = moment();
                    const today = now.startOf('day');
                    const future = now.clone().add(10 , 'years');
                    console.log(today.toISOString());
                    this.cookieService.set('lastSurveyReminderDate', today.toISOString(), future.toDate() );
                }
            }
            if (result === 'dontAskAgain') {
                if (isNewUser) {
                    this.user.preference.lastSurvey = moment('1970-01-01').format('YYYY-MM-DDTHH:mm:ss.SSS');
                } else {
                    this.user.preference.queuedSurvey = '';
                }
                this.sdUserService.saveUser(this.user);

                // this shouldn't exist, but just in case
                this.cookieService.delete('mustCompleteSurveyByDate');
                this.cookieService.delete('lastSurveyReminderDate');
            }
            if (result === 'letsGo') {
                if (!this.showNotNow && this.user.isSurveyRequired) {
                    // survey is required
                    this.allowNavigation = false;
                }
                this.router.navigate(['/profile']);
            }
        }).catch((error) => {
            // do nothing
        });
    }

}
