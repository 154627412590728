import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScreenSizeService {
    private screenSize = 0;
    private screenSizeSubject: BehaviorSubject<number> = new BehaviorSubject(this.screenSize);
    public getScreenSize(): Observable<number> {
        return this.screenSizeSubject.asObservable();
    }
    public setScreenSize(size: number) {
        this.screenSize = size;
        this.screenSizeSubject.next(this.screenSize);
    }
}
