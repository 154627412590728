import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {LifeDeskService, LifeDeskStatus} from './life-desk.service';
import {SDUserService} from './user.service';
import {User} from './models/user';
import {Observable, Observer, Subscription} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RemoteService implements OnDestroy {

    public targetHeight;
    user: User = null;
    public shouldUpdateHeightDisplayObservable: Observable<any>;
    private shouldUpdateHeightDisplayObserver: Observer<number>;
    private subscription: Subscription;

    constructor(private lifeDeskService: LifeDeskService, private sdUserService: SDUserService) {
        this.shouldUpdateHeightDisplayObservable = new Observable(observer => {
            this.shouldUpdateHeightDisplayObserver = observer;
        }).pipe(share());
        this.init();
    }

    init() {
        console.log('remote service init');
        this.subscription = new Subscription();
        // do need to unsubscribe to an infinite observable that doesn't call complete()
        const heightSubscription = this.lifeDeskService.heightObservable().subscribe((heightInInches) => {
            if (this.lifeDeskService.status === LifeDeskStatus.MOVING_TO_TARGET) {
                if (Math.round(this.targetHeight * 10) !== Math.round(heightInInches * 10)) {
                    // We're not there yet, keep going
                    console.log('didUpdateHeight MOVING_TO_TARGET - ' + this.targetHeight.toFixed(1));
                    this.moveToTargetHeight(this.targetHeight, false);
                } else {
                    // Target height reached, so stop trying
                    console.log('didUpdateHeight MOVING_TO_TARGET - Target Reached');
                    this.lifeDeskService.status = LifeDeskStatus.NOT_MOVING;
                }
            }
        });
        this.subscription.add(heightSubscription);
        // const userSubscription = this.userService.getUser.subscribe((user) => {
        //     console.log('trying to get user');
        //     this.user = user;
        // });
        // this.subscription.add(userSubscription);

        // don't need to unsubscribe to a finite observable that calls complete(), but this one is ongoing;
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            console.log('remote service got the user');
            this.user = user;
        });
        this.subscription.add(userSubscription);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    moveToTargetHeight(height: number, shouldRetry: boolean) {
        this.lifeDeskService.moveToTargetHeight(height);
        // TODO this should be done in service with observable
        if (shouldRetry) {
            setTimeout(() => {
                this.lifeDeskService.moveToTargetHeight(height);
                console.log('try two');
            }, 200);
            setTimeout(() => {
                this.lifeDeskService.moveToTargetHeight(height);
                console.log('try three');
            }, 400);
        }
    }

    moveToMemoryPosition(position: number): boolean {
        let target = 0;
        if (this.user) {
            switch (position) {
                case 1:
                {
                    target = this.user.preference.memoryOne;
                }
                    break;
                case 2:
                {
                    target = this.user.preference.memoryTwo;
                }
                    break;
                default:
                    break;
            }
            console.log(target);
            if (target > 0 ) {
                this.targetHeight = target;
                this.lifeDeskService.status = LifeDeskStatus.MOVING_TO_TARGET;
                this.moveToTargetHeight(this.targetHeight, true);
                // need to update height display
                if (this.shouldUpdateHeightDisplayObserver) {
                    this.shouldUpdateHeightDisplayObserver.next(this.targetHeight);
                }
                this.lifeDeskService.resetIdleTimer();
                return true;
            } else {
                return false;
            }
        }
    }

    shouldChangePosition(): boolean {
        const position = (this.lifeDeskService.position === 'stand') ? 1 : 2;
        if (this.moveToMemoryPosition(position)) {
            return true;
        } else {
            return false;
        }
    }
}
